import { FC, useEffect, useState } from "react";
import Modal from "../Modal";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { ShipmentInterface } from "../../interfaces";
import FingerprintReader from "../FingerprintReader";
import WebcamCapture from "../Webcam";
import { FormTextInput, FormTextAreaInput } from "../FormFields";
import { loaderService } from "../../services";

interface OriginModalProps {
  openModal: boolean;
  shipmentNumbers: string[];
  shipments?: ShipmentInterface[];
  consigneeName: string;
  consigneeId: string;
  fingerprint?: string;
  photo?: string;
  signatoryName: string;
  signatoryId: string;
  setFingerprint: (fingerprint?: string) => void;
  setPhoto: (photo?: string) => void;
  setSignatoryName: (name: string) => void;
  setSignatoryId: (id: string) => void;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}
export const OriginModal: FC<OriginModalProps> = ({
  openModal,
  setOpenModal,
  shipments,
  shipmentNumbers,
  consigneeName,
  consigneeId,
  fingerprint,
  setFingerprint,
  photo,
  setPhoto,
  signatoryName,
  setSignatoryName,
  signatoryId,
  setSignatoryId,
}) => {
  const loading = loaderService.useIsLoading();
  const [nameError, setNameError] = useState("");
  const [idError, setIdError] = useState("");
  const [observation, setObservation] = useState("");
  const [fingerprintTemplate, setFingerprintTemplate] = useState<
    string | undefined
  >();

  const canSubmit = () => {
    return !loading && signatoryName && signatoryId && signatoryId.length >= 7;
  };

  useEffect(() => {
    setSignatoryName(consigneeName);
    setSignatoryId(consigneeId);
  }, [consigneeName, consigneeId, setSignatoryName, setSignatoryId]);

  useEffect(() => {
    if (signatoryName === "")
      setNameError("El nombre del remitente es requerido");
    else if (signatoryName !== "") setNameError("");
  }, [signatoryName]);

  useEffect(() => {
    if (signatoryId === "") setIdError("La cédula de identidad es requerida");
    else if (signatoryId !== "") setIdError("");
    if (signatoryId.length < 7 && signatoryId !== "")
      setIdError("La cédula debe tener al menos 7 dígitos");
  }, [signatoryId]);

  return (
    <Modal
      openModal={openModal}
      setOpenModal={() => null}
      className="w-full max-w-[40rem]"
    >
      <div className="flex flex-col md:flex-row md:gap-2 mb-4">
        <p className="font-light text-xl">
          Declaración de guías:{" "}
          <span className="text-indigo-600">{shipmentNumbers.join(", ")}</span>
        </p>
      </div>

      <div className="flex justify-center items-center mb-4">
        <div className="bg-gray-100 p-4 rounded border border-gray-300 text-center">
          <p className="text-sm">
            <span className="font-bold">
              Declaro bajo fe de juramento, libre de apremio, coerción y por
              voluntad propia que:
            </span>
            <br />
            <span className="font-bold"> 1.</span> La encomienda consignada a
            <span className="font-bold"> TEALCA </span> tiene un origen, fines y
            naturaleza lícitos, no contiene ningún tipo de sustancia
            psicotrópica, estupefaciente, precursora o cualquiera señalada en la
            Ley Orgánica de Drogas ni bienes contrarios a la legislación
            venezolana vigente, y cumple con las{" "}
            <span className="font-bold">
              {" "}
              CONDICIONES GENERALES DEL SERVICIO{" "}
            </span>
            encontradas en{" "}
            <a
              className="text-indigo-600"
              href="https://www.tealca.com/legal/terminos-condiciones/"
            >
              https://www.tealca.com/legal/terminos-condiciones/
            </a>{" "}
            los cuales acepto íntegramente.
            <br />
            <span className="font-bold"> 2.</span> Acepto que la guía podrá ser
            objeto de inspección por parte de las autoridades competentes en
            materia de drogas u otras, por lo que libero de toda responsabilidad
            y mantendré indemne a <span className="font-bold"> TEALCA </span>,
            sus directivos y trabajadores, de toda responsabilidad en caso de
            efectuarse una retención o incautación de la guía por parte de las
            autoridades.
            <br />
            <span className="font-bold"> 3.</span> Soy responsable por todos
            aquellos daños, perjuicios y gastos ocasionados a{" "}
            <span className="font-bold"> TEALCA </span> y/o terceros producto de
            la falsedad o ausencia los datos aquí provistos y/o los documentos
            necesarios para transportar la encomienda.
          </p>
        </div>
      </div>

      <div className="mb-6">
        <FormTextInput
          label="Nombre del remitente"
          isRequiredLabel
          name="fullName"
          type="text"
          value={signatoryName}
          error={nameError}
          onChange={(e) => {
            const value = e.target.value;
            if (/^[a-zA-Z\s]*$/.test(value)) {
              setSignatoryName(value);
            }
          }}
        />
      </div>
      <div className="mb-6">
        <FormTextInput
          label="Cédula de Identidad"
          isRequiredLabel
          name="identityCard"
          type="text"
          value={signatoryId}
          error={idError}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*$/.test(value)) {
              setSignatoryId(value);
            }
          }}
        />
      </div>

      <div className="flex flex-col items-center mb-6">
        <WebcamCapture photo={photo} setPhoto={setPhoto} />
      </div>
      <div className="flex flex-col mb-6">
        <FingerprintReader
          fingerprint={fingerprint}
          setFingerprint={setFingerprint}
          setTemplate={setFingerprintTemplate}
        />
      </div>

      <FormTextAreaInput
        rows={4}
        maxLength={150}
        id="observations"
        name="observations"
        label="Observaciones"
        className="resize-none"
        onChange={(e) => setObservation(e.target.value)}
        value={observation}
      />

      <hr className="my-4" />

      <div className="flex w-full items-center justify-between">
        <SecondaryButton
          className="w-32"
          onClick={() => {
            setOpenModal(false);
            setFingerprint(undefined);
            setPhoto(undefined);
          }}
        >
          Cancelar
        </SecondaryButton>

        <PrimaryButton
          disabled={!canSubmit()}
          className="w-32"
          onClick={() => setOpenModal(false)}
        >
          Guardar
        </PrimaryButton>
      </div>
    </Modal>
  );
};
