import { useEffect, useState } from "react";
import { BackButton, PrimaryButton } from "../components/Buttons";
import {
  ManifestHeaderInterface,
  ManifestReceiptPhase,
  ManifestDetailInterface,
  ManifestStatus,
  ManifestType,
} from "../interfaces/ManifestInterface";

import LoadingIcon from "../components/LodingIcon";
import ManifestHeaderTable from "../components/Manifest/ManifestHeaderTable";
import moment from "moment";
import {
  getManifestDetail,
  getManifestPosponed,
  updateManifestStatus,
} from "../services/manifestServices";
import ManifestDetailModal from "../components/Manifest/ManifestDetailModal";
import classNames from "classnames";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import SearchManifest from "../components/Manifest/SearchManifest";
import { useAppSelector } from "../store/hooks";
import ReusableModal from "../components/Manifest/ReusableModal";
import { useDispatch } from "react-redux";
import { removeManifest } from "../store/slices/manifest";
import NewReceipt from "./Manifest/NewReceipt";
import HorizontalPadding from "../components/HorizontalPadding";
import { alertService } from "../services";
import LoadingModal from "../components/Manifest/LoadingModal";

interface ReceiptFormState {
  masterShipmentHeaderCode: string;
  statusID?: number | null;
}

const Receipt = () => {
  const [postponedData, setPostponedData] = useState<
    ManifestHeaderInterface[] | null
  >([]);
  const [generatedData, setGeneratedData] = useState<
    ManifestHeaderInterface[] | null
  >([]);
  const [isLoadingPost, setIsLoadingPost] = useState(false);
  const [isLoadingGene, setIsLoadingGene] = useState(false);
  const [selectedManifest, setSelectedManifest] =
    useState<ManifestHeaderInterface | null>(null);

  // const [selectedManifestDraft, setSelectedManifestDraft] =
  //   useState<ManifestHeaderInterface | null>(null);
  // const [isNewManifestDraft, setIsNewManifestDraft] = useState(false);
  // const [detailDataDraft, setDetailDataDraft] = useState<
  //   ManifestDetailInterface[] | null
  // >([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [detailData, setDetailData] = useState<
    ManifestDetailInterface[] | null
  >([]);
  // const [isNewManifest, setIsNewManifest] = useState(false);
  const [phase, setPhase] = useState<ManifestReceiptPhase>(
    ManifestReceiptPhase.RECEIPT_HOME
  );
  const [openReceiptHomeModal, setOpenReceiptHomeModal] = useState(false);
  const [openNewReceiptModal, setOpenNewReceiptModal] = useState(false);

  const [manifestFormState, setManifestFormState] = useState<ReceiptFormState>({
    masterShipmentHeaderCode: "",
    statusID: null,
  });
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const receipt = useDispatch();
  const manifests = useAppSelector((state) => state.manifest.manifests);
  const [isLoadingDetail, setisLoadingDetail] = useState(false);

  // const selectTab = (tab: number) => {
  //   setTab(tab);
  // };

  // {phase === ShipmentCreatePhase.DOCUMENT && (
  //                 <div className="flex flex-col">
  //                   <div>
  //                     <BackButton
  //                       handleOnRegret={() =>
  //                         dispatch(
  //                           setShipmentCreatePhase(ShipmentCreatePhase.SHIPMENTS)
  //                         )
  //                       }
  //                     />
  //                   </div>
  //                 </div>
  //               )}

  const selectPhase = (phase: ManifestReceiptPhase) => {
    setPhase(phase);
  };

  // Redux state
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    // Realiza la búsqueda inicial al montar el componente
    const fetchInitialData = async () => {
      const buCode = user.businessUnit?.code;
      if (!buCode) return;

      setIsLoadingPost(true);

      // se busca en redux si se tiene un manifiesto de recepción guardado para la unidad de negocio
      let manifestHeaderLocal = manifests.find(
        (x) =>
          x.manifestTypeID === ManifestType.RECEPCION &&
          x.buCodeDestination === buCode
      );

      if (manifestHeaderLocal) {
        handleSearch([manifestHeaderLocal], "postponed");
      } else {
        const fechaActual = moment();
        const initialData = await getManifestPosponed(
          ManifestType.RECEPCION,
          fechaActual.subtract(3, "days").toDate(),
          moment().toDate(),
          null,
          buCode
        );

        handleSearch(
          !initialData.didError ? initialData?.model : null,
          "postponed"
        );
      }

      setIsLoadingPost(false);
    };

    fetchInitialData();
  }, [manifests, user.businessUnit?.code]);

  useEffect(() => {
    if (!!manifestFormState.masterShipmentHeaderCode && shouldFetchData) {
      selectPhase(ManifestReceiptPhase.RECEIPT_NEW);
    }
  }, [shouldFetchData, manifestFormState.masterShipmentHeaderCode]);

  useEffect(() => {
    if (detailData && selectedManifest) {
      setIsModalOpen(true);
    }
  }, [detailData, selectedManifest]);

  // se asigna el resultado obtenido del API
  const handleSearch = (
    searchData: ManifestHeaderInterface[] | null,
    type: "postponed" | "generated"
  ) => {
    if (searchData && searchData.length > 0) {
      searchData.sort((a, b) => {
        const codeA = a.masterShipmentHeaderCode || "";
        const codeB = b.masterShipmentHeaderCode || "";
        return codeB.localeCompare(codeA);
      });
      if (type === "postponed") {
        setPostponedData(searchData);
      } else {
        setGeneratedData(
          searchData.filter(
            (x) => x.buCodeDestination === user.userSession?.buCode
          )
        );
      }
    }
  };

  // click al table manifestHeader, y su status es Generado = 35
  const handleRowClick = async (manifest: ManifestHeaderInterface) => {
    setSelectedManifest(manifest);
    let detailDa = null;

    if (manifest?.masterShipmentHeaderCode) {
      // setIsLoadingGene(true);
      setisLoadingDetail(true);
      detailDa = await getManifestDetail(manifest.masterShipmentHeaderCode);
    }

    if (detailDa && detailDa[0].manifestDetail) {
      // ordenar por shipmentNumber y pieza
      const sortedData = detailDa[0].manifestDetail.sort((a, b) => {
        if (a.shipmentNumber < b.shipmentNumber) {
          return -1;
        }
        if (a.shipmentNumber > b.shipmentNumber) {
          return 1;
        }

        return a.pieceNumber - b.pieceNumber;
      });

      setDetailData(sortedData);
    }

    setisLoadingDetail(false);
  };

  // const handleRowDraftClick = async (manifest: ManifestHeaderInterface) => {
  //   setSelectedManifestDraft(manifest);
  //   setIsNewManifestDraft(true);

  //   let detailDataDraf = null;

  //   if (manifest.masterShipmentHeaderCode) {
  //     detailDataDraf = await getManifestDetail(
  //       manifest.masterShipmentHeaderCode
  //     );

  //     if (detailDataDraf && detailDataDraf[0].manifestDetail) {
  //       setDetailDataDraft(
  //         detailDataDraf ? detailDataDraf[0].manifestDetail : null
  //       );
  //     }
  //   }
  // };

  const handleOnRegret = () => {
    const { masterShipmentHeaderCode, statusID } = manifestFormState;
    if (masterShipmentHeaderCode) {
      if (statusID) {
        // setOpenReceiptHomeModal(true);
        alertService.success(
          "Manifiesto de recepción",
          `El manifiesto '${masterShipmentHeaderCode}' ha sido postergado exitosamente`
        );
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        setPhase(ManifestReceiptPhase.RECEIPT_HOME);
      }
    } else {
      setPhase(ManifestReceiptPhase.RECEIPT_HOME);
    }
  };

  // recuperar el manifiesto postergado al darle clic en la tabla manifestHeader
  const handleShouldDraft = () => {
    let rowDraft = postponedData?.find(
      (x) => x.statusID === ManifestStatus.POSTERGADO
    );

    if (rowDraft?.masterShipmentHeaderCode) {
      setManifestFormState({
        ...manifestFormState,
        masterShipmentHeaderCode: rowDraft?.masterShipmentHeaderCode,
        statusID: rowDraft?.statusID,
      });
      setShouldFetchData(true);
    } else {
      setShouldFetchData(false);
    }
  };

  const handleCancel = () => {
    setOpenReceiptHomeModal(false);
  };

  const handleAcceptNew = async () => {
    setOpenReceiptHomeModal(false);
    selectPhase(ManifestReceiptPhase.RECEIPT_NEW);

    // se debe mantener solo (1) un objeto de manifesto de recepcion en el local storage
    // en caso de ya exitir uno, se manda a eliminar
    let removeLocalManifest = manifests.find(
      (x) =>
        x.manifestTypeID === ManifestType.RECEPCION &&
        x.buCodeDestination === user.businessUnit?.code
    );

    if (removeLocalManifest) {
      receipt(
        removeManifest(removeLocalManifest?.masterShipmentHeaderID as string)
      );
    }

    let manifestUpdateStatus = postponedData?.find(
      (x) => x.statusID === ManifestStatus.POSTERGADO
    )?.masterShipmentHeaderID;

    // actualizar el estatus del manifiesto que se esta descartando. 'Inactivarlo'
    if (manifestUpdateStatus) {
      await updateManifestStatus(
        manifestUpdateStatus,
        ManifestStatus.INACTIVO,
        user.user?.userLogin as string
      );
    }
  };

  const handleAcceptHome = () => {
    setOpenReceiptHomeModal(false);
    setPhase(ManifestReceiptPhase.RECEIPT_HOME);
  };

  const message =
    manifestFormState.statusID === 31
      ? "¿Está seguro de salir de la pantalla, sin guardar en borrador ó cerrar el manifiesto? ¡Se perderá la información!"
      : `¿Está seguro de salir de la pantalla? El manifiesto ya está en estatus postergado, código ${manifestFormState.masterShipmentHeaderCode}`;

  const messageNewReceipt =
    "¿Esta seguro de crear una nueva recepción? ¡Tiene una guardada en borrador, si continua se perderá la información!";

  const handleNewReceipt = () => {
    if (
      postponedData &&
      postponedData?.some((x) => x.statusID === ManifestStatus.POSTERGADO)
    ) {
      setOpenNewReceiptModal(true);
    } else {
      setManifestFormState({ masterShipmentHeaderCode: "", statusID: null });
      setShouldFetchData(false);
      selectPhase(ManifestReceiptPhase.RECEIPT_NEW);
    }
  };

  return (
    <main className="lg:pl-72 pb-32">
      {/* Header */}
      {phase === ManifestReceiptPhase.RECEIPT_HOME && (
        <div className="py-6 sm:px-6 lg:px-8 bg-white relative flex items-center justify-between h-32">
          <header className="ml-4 text-2xl font-bold text-gray-700 ">
            Manifiesto de Recepción
          </header>
          <PrimaryButton className="pr-4" onClick={handleNewReceipt}>
            <DocumentTextIcon
              className="h-5 w-5 flex-none text-gray-200 mr-1"
              // aria-hidden="true"
            />
            Nuevo
          </PrimaryButton>
        </div>
      )}
      {phase === ManifestReceiptPhase.RECEIPT_NEW && (
        <div className="py-6 sm:px-6 lg:px-8 bg-white relative flex items-center justify-between h-32">
          <header className="ml-4 text-2xl font-bold text-gray-700 ">
            Crear Recepción
          </header>
        </div>
      )}
      <div className="pt-6">
        <HorizontalPadding>
          {phase === ManifestReceiptPhase.RECEIPT_HOME && (
            <>
              {/* Header*/}
              <div
                className={classNames(
                  "flex flex-1 flex-col bg-white gap-4 rounded-lg border px-6 py-6 w-full"
                )}
              >
                <h1 className="text-2xl font-semibold text-gray-800 truncate mb-2">
                  Manifiesto en Borrador
                </h1>
                {/* <SearchManifest manifestType={1} onSearch={handleSearch} /> */}
                <div
                  className={classNames(
                    "flex flex-1 flex-row bg-white gap-4 rounded-lg"
                  )}
                >
                  {isLoadingPost ? (
                    <LoadingIcon />
                  ) : (
                    <ManifestHeaderTable
                      data={
                        postponedData?.find(
                          (x) => x.statusID === ManifestStatus.POSTERGADO
                        )
                          ? [
                              postponedData.find(
                                (x) => x.statusID === ManifestStatus.POSTERGADO
                              )!,
                            ]
                          : []
                      }
                      onRowClick={handleShouldDraft}
                      Isfilter={false}
                    />
                  )}
                </div>
              </div>
              <div
                className={classNames(
                  "flex flex-1 flex-col bg-white gap-4 rounded-lg border px-6 py-6 w-full mt-6"
                )}
              >
                <h1 className="text-2xl font-semibold text-gray-800 truncate mb-4">
                  Manifiestos Generados
                </h1>
                <SearchManifest
                  manifestType={ManifestType.RECEPCION}
                  onSearch={(data) => handleSearch(data, "generated")}
                  setIsLoadingGene={setIsLoadingGene}
                />
                <div
                  className={classNames(
                    "flex flex-1 flex-row bg-white gap-4 rounded-lg"
                  )}
                >
                  {isLoadingGene ? (
                    <LoadingIcon />
                  ) : (
                    <ManifestHeaderTable
                      data={generatedData?.filter(
                        (x) => x.statusID === ManifestStatus.GENERADO
                      )}
                      onRowClick={handleRowClick}
                      Isfilter={true}
                    />
                  )}
                </div>
              </div>
            </>
          )}
          {phase === ManifestReceiptPhase.RECEIPT_NEW && (
            <>
              <div className="mb-6">
                <BackButton handleOnRegret={handleOnRegret} />
              </div>
              <NewReceipt
                setManifestFormState={setManifestFormState}
                shouldFetchData={shouldFetchData}
                manifestFormState={manifestFormState}
              />
            </>
          )}
        </HorizontalPadding>
      </div>

      {isModalOpen && selectedManifest && (
        <ManifestDetailModal
          openModal={isModalOpen}
          setOpenModal={setIsModalOpen}
          manifest={selectedManifest}
          detailData={detailData}
        />
      )}
      {/* Modal de consulta si esta creando una recepcion y quiere regresar a la pantalla principal de recepcion */}
      <ReusableModal
        openModal={openReceiptHomeModal}
        setOpenModal={setOpenReceiptHomeModal}
        title="Creación de recepción"
        message={message}
        iconType="question"
        onCancel={handleCancel}
        onAccept={handleAcceptHome}
        showCancelButton={true}
        cancelButtonText="Cancelar"
        acceptButtonText="Aceptar"
      />
      {/* Modal de consulta si esta en la pantalla principal de recepcion y quiere crear una nueva */}
      <ReusableModal
        openModal={openNewReceiptModal}
        setOpenModal={setOpenNewReceiptModal}
        title="Creación de recepción"
        message={messageNewReceipt}
        iconType="question"
        onCancel={handleCancel}
        onAccept={handleAcceptNew}
        showCancelButton={true}
        cancelButtonText="Cancelar"
        acceptButtonText="Aceptar"
      />
      <LoadingModal
        openModal={isLoadingDetail}
        setOpenModal={setisLoadingDetail}
        blockUserInput={false}
      />
    </main>
  );
};

export default Receipt;
