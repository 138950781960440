import api from "./api";
import {
  BusinessUnitInterface,
  Nullable,
  ResponseInterface,
} from "../interfaces";
import { UserInterface } from "../interfaces/UserInterface";
import { FileDocument } from "./shipmentServices";

const baseURLAPI = process.env.REACT_APP_API_HOST;

interface PODInsertResponse {
  podid: string;
}

export const savePOD = async (
  shipmentId: string,
  accountID: string,
  deliveryPersonName: string,
  observation: string,
  user: UserInterface,
  businessUnit: BusinessUnitInterface
): Promise<ResponseInterface<PODInsertResponse>> => {
  const uri = `${baseURLAPI}/POD`;
  const body = JSON.stringify({
    BUCodeDelivery: businessUnit.code,
    DeliveryAuthorization: false,
    DeliveryDate: new Date().toISOString(),
    AccountID: accountID,
    ShipmentID: shipmentId,
    deliveryPersonName,
    DeliveryObservation: observation,
    CreationUser: user.userLogin,
    CreationDate: new Date().toISOString(),
  });
  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    return (error as any)?.response?.data;
  }
};

// Add AM to service
export const savePODAttachment = async (
  fingerprint: File | undefined,
  photoAttachment: File | undefined,
  podID: string,
  creationUser: string,
  statusID: number
): Promise<ResponseInterface<boolean>> => {
  const uri = `${baseURLAPI}/POD/InsertAttachment`;
  const formData = new FormData();
  if (fingerprint) {
    const fingerprintBlob = new Blob([fingerprint], { type: "image/png" });
    formData.append("Fingerprint", fingerprintBlob, "fingerprint.png");
  }
  if (photoAttachment) formData.append("PhotoAttachment", photoAttachment);
  formData.append("PODID", podID);
  formData.append("CreationUser", creationUser);
  formData.append("StatusID", statusID.toString());

  try {
    const response = await api.post(uri, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    return (error as any)?.response?.data;
  }
};

export const getPODDocument = async (
  shipmentNumbers: string[]
): Promise<Nullable<FileDocument>> => {
  const uri = `${baseURLAPI}/v1/Shipment/DownloadDocumentDelivery`;

  const body = JSON.stringify({
    List: shipmentNumbers.map((shipmentNumber) => ({ shipmentNumber })),
    type: 0,
  });

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
    });

    if (response.status === 200) {
      return {
        file: response.data,
        fileName: `PODDocument_${shipmentNumbers[0]}.pdf`,
      };
    }
  } catch (error) {}
  return null;
};
