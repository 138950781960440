import { useEffect, useRef, useState } from "react";
import BarcodeScannerComponent, {
  BarcodeScannerComponentHandle,
} from "../../components/BarcodeScannerComponent";
import { PrimaryButton } from "../../components/Buttons";
import { FormTextInput } from "../../components/FormFields";
import { ShipmentDetailResumen, ShipmentGuideModel } from "../../interfaces";
import DriverSearch, { Driver } from "../../components/Manifest/DriverSearch";
import {
  AccountDrivers,
  DispatchManifestForm,
  InfoDataManifest,
  ManifestDetailInterface,
  ManifestDetailSingleInterface,
  ManifestHeaderInterface,
  ManifestHeaderUpdateInterface,
  ManifestIncidence,
  ManifestStatus,
  ManifestType,
  Routes,
  Vehicles,
} from "../../interfaces/ManifestInterface";
import {
  createManifestDetail,
  createManifestHeader,
  createObjectDispatchManifestForm,
  createObjectDispManiFromManifestHeader,
  createObjectManifestDetail,
  createObjectManifestDetailSingle,
  createObjectManifestHeader,
  generateManifest,
  getDrivers,
  getManifestPostponedByCode,
  getOfficeShipment,
  getPlatforms,
  getRoute,
  getVehicles,
  updateDetailStatusDeleted,
  updateManifestHeader,
  updateManifestStatus,
  validationManifestDetail,
} from "../../services/manifestServices";
import ManifestDetailsTable from "../../components/Manifest/ManifestDetailTable";
import { useAppSelector } from "../../store/hooks";
import VehicleSearch from "../../components/Manifest/VehicleSearch";
import { alertService } from "../../services/alertService";
import { useDispatch } from "react-redux";

import {
  addDataBasicManifest,
  addManifest,
  removeManifest,
  setManifestDetails,
  updateManifest,
} from "../../store/slices/manifest";
import ReusableModal from "../../components/Manifest/ReusableModal";
import NumericInput from "../../components/NumericInput";
import LoadingModal from "../../components/Manifest/LoadingModal";

// interface NewManifestModalProps {
//   openNewManifest: boolean;
//   setOpenNewManifest: React.Dispatch<React.SetStateAction<boolean>>;
// }

let isManualShipmentEntry = false;
let shipmentDetailEntered: ShipmentDetailResumen[] = [];
let shipmentEntered: ShipmentGuideModel | null = null;

interface DispatchStatusProps {
  setManifestFormState: React.Dispatch<
    React.SetStateAction<{
      masterShipmentHeaderCode: string;
      statusID?: number | null;
    }>
  >;

  shouldFetchData: boolean;
  manifestFormState: {
    masterShipmentHeaderCode: string;
    statusID?: number | null;
  };
}

const NewDispatch: React.FC<DispatchStatusProps> = ({
  setManifestFormState,
  shouldFetchData,
  manifestFormState,
}) => {
  // Redux state
  const user = useAppSelector((state) => state.user);
  const services = useAppSelector((state) => state.inmutable.businessServices);

  const dispatch = useDispatch();
  const manifestInfoBasic = useAppSelector(
    (state) => state.manifest.infoDataManifest
  );
  const manifests = useAppSelector((state) => state.manifest.manifests);

  const [openClearManifestModal, setOpenClearManifestModal] = useState(false);
  const [openGenerateManifestModal, setOpenGenerateManifestModal] =
    useState(false);
  const [openClickRowManifestDetailModal, setOpenClickRowManifestDetailModal] =
    useState(false);
  const [selectedShipmentNumber, setSelectedShipmentNumber] = useState("");
  const [messageRowManifestDelete, setMessageRowManifestDelete] = useState("");
  const barcodeRef = useRef<BarcodeScannerComponentHandle>(null);
  const [vehicleData, setVehicleData] = useState<Vehicles[] | null>();
  const [driverData, setDriverData] = useState<AccountDrivers[] | null>();
  const [isDisabledBtnSave, setIsDisabledBtnSave] = useState<boolean>(true);
  const [isMounted, setIsMounted] = useState(false);
  const [formState, setFormState] = useState<DispatchManifestForm>({
    masterShipmentHeaderID: "",
    masterShipmentHeaderCode: "",
    route: "",
    routeID: null,
    consigneeName: "",
    buCodeConsignee: "",
    vehicle: "",
    vehicleID: "",
    transportID: "",
    transportModelName: "",
    transportPlate: "",
    driver: "",
    driver1ID: "",
    driver1IdentificationNumber: "",
    driver1Name: "",
    seal1: "",
    seal2: "",
    seal3: "",
    shipmentNumber: "",
    piece: 0,
    buCodeSource: "",
    buSource: "",
    manifestType: ManifestType.DESPACHO,
    masterShipmentHeaderObservation: "",
    statusID: null,
    totalShipments: 0,
    totalPieces: 0,
    masterShipmentHeaderIDDispatch: "",
    masterShipmentHeaderCodeDispatch: "",
  });
  const [data, setData] = useState<ManifestDetailSingleInterface[]>([]);
  const dataRef = useRef(data);
  const masterShipmentHeaderIDRef = useRef(formState.masterShipmentHeaderID);
  const [isLoading, setIsLoading] = useState(false);
  const [blockUserInput, setBlockUserInput] = useState(false);
  const [isLoadingBlurShipment, setIsLoadingBlurShipment] = useState(false);
  const [isLoadingDeleteRows, setIsLoadingDeleteRows] = useState(false);
  const [openSuccessGenerate, setOpenSuccessGenerate] = useState(false);
  const [openErrorCreateManifestHeader, setOpenErrorCreateManifestHeader] =
    useState(false);
  const [messageErrorCreateManifest, setMessageErrorCreateManifest] =
    useState("");
  const [error, setError] = useState<string | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const isFetchingRef = useRef(isFetching);
  // const inputSeal1Ref = useRef<HTMLInputElement>(null);

  const handleAcceptSuccessManifest = async () => {
    setOpenSuccessGenerate(false);
    setBlockUserInput(false);
    setIsLoadingDeleteRows(false);
    window.location.reload();
  };

  const handleAcceptErrorGenerarManifest = async () => {
    setOpenErrorCreateManifestHeader(false);
    // inputSeal1Ref.current?.focus();
  };

  const updateManifestDetails = async () => {
    let rowManifestDetail = createObjectManifestDetail(
      dataRef.current,
      formState.masterShipmentHeaderID,
      user.user?.userLogin as string,
      user.businessUnit?.code as string
    );

    let updatedData = dataRef.current;

    if (rowManifestDetail?.length > 0) {
      let newRowManifestDetail = await createManifestDetail(rowManifestDetail);

      if (!newRowManifestDetail.didError) {
        updatedData = dataRef.current.map((item) => {
          const savedItem = newRowManifestDetail?.model?.find(
            (detail) => detail.shipmentNumber === item.shipmentNumber
          );
          return savedItem
            ? { ...item, containerDetailID: savedItem.containerDetailID }
            : item;
        });

        // setData(updatedData);

        let detailSingle = createObjectManifestDetail(
          updatedData,
          formState.masterShipmentHeaderID,
          user.user?.userLogin as string,
          user.businessUnit?.code as string,
          false
        );

        // Actualizar los detalles en Redux
        handleReduxSetManifestDetails(
          formState.masterShipmentHeaderID,
          detailSingle
        );

        const pendingData = newRowManifestDetail?.model?.some(
          (item) => !item.containerDetailID
        );

        setIsDisabledBtnSave(pendingData ?? true);
      } else {
        alertService.error(newRowManifestDetail.errorMessage);
        // return; // Salir si hay un error en la inserción
      }
    }

    // Validar registros pendientes
    let shipmentPendingValidation = updatedData.filter(
      (x) => x.incidence === ManifestIncidence.NOT_VALIDATED || !x.incidence
    );

    if (shipmentPendingValidation.length > 0) {
      try {
        const responseData = await validationManifestDetail(
          shipmentPendingValidation,
          user.userSession?.buCode as string,
          ManifestType.DESPACHO
        );

        const finalUpdatedData = updatedData.map((item) => {
          const updatedItem = responseData?.model?.find(
            (responseItem) =>
              responseItem.shipmentNumber === item.shipmentNumber
          );
          if (updatedItem) {
            return {
              ...item,
              shipmentDetailID: updatedItem.shipmentDetailID,
              incidence: updatedItem.incidence,
              // warnings: updatedItem.warnings,
              errors: updatedItem.errors,
            };
          }
          return item;
        });

        setData(finalUpdatedData);

        let detailSingle = createObjectManifestDetail(
          finalUpdatedData,
          formState.masterShipmentHeaderID,
          user.user?.userLogin as string,
          user.businessUnit?.code as string,
          false
        );

        // Actualizar los detalles en Redux
        handleReduxSetManifestDetails(
          formState.masterShipmentHeaderID,
          detailSingle
        );

        const pendingData = finalUpdatedData.some(
          (item) => !item.containerDetailID
        );

        setIsDisabledBtnSave(pendingData ?? true);
      } catch (error) {
        alertService.error(`Error validando los detalles del manifiesto`);
      }
    } else {
      setData(updatedData);
    }
  };

  // carga iniciales
  useEffect(() => {
    const fetchData = async () => {
      try {
        const buCode = user.businessUnit?.code;

        let manifest: DispatchManifestForm = {
          masterShipmentHeaderID: "",
          masterShipmentHeaderCode: "",
          route: "",
          routeID: 0,
          consigneeName: "",
          buCodeConsignee: "",
          vehicle: "",
          vehicleID: "",
          transportID: "",
          transportModelName: "",
          transportPlate: "",
          driver: "",
          driver1ID: "",
          driver1IdentificationNumber: "",
          driver1Name: "",
          seal1: "",
          seal2: "",
          seal3: "",
          shipmentNumber: "",
          piece: 0,
          buCodeSource: buCode as string,
          buSource: "",
          manifestType: ManifestType.DESPACHO,
          masterShipmentHeaderObservation: "",
          totalShipments: 0,
          totalPieces: 0,
          masterShipmentHeaderIDDispatch: "",
          masterShipmentHeaderCodeDispatch: "",
        };

        let infoMani = manifestInfoBasic?.find((x) => x.buSource === buCode);
        let platFormData = null;
        let infoRoute: Routes[] | null = null;

        if (infoMani) {
          infoRoute = [
            {
              routeID: Number(infoMani.routeID),
              routeName: infoMani.routeName,
              buCodeConsignee: infoMani.buCodeConsignee,
              buConsigneeName: infoMani.buConsigneeName,
            },
          ];

          platFormData = [
            {
              buCode: infoMani.buCodeConsignee,
              buName: infoMani.buConsigneeName,
            },
          ];
        } else {
          [platFormData, infoRoute] = await Promise.all([
            (platFormData = await getPlatforms()),
            getRoute(buCode as string),
          ]);

          const defaultRoute = {
            buCodeConsignee: "",
            buConsigneeName: "",
            routeID: 0,
            routeName: "",
          };

          const routeInfo =
            infoRoute && infoRoute.length > 0 ? infoRoute[0] : defaultRoute;

          const infoMan: InfoDataManifest = {
            buCodeConsignee: routeInfo.buCodeConsignee,
            buConsigneeName: routeInfo.buConsigneeName,
            routeID: routeInfo.routeID?.toString() ?? "",
            routeName: routeInfo.routeName.toUpperCase(),
            buSource: user.businessUnit?.code ?? "",
          };

          // se guarda en el localStorage
          handleReduxAddInfoManifest(infoMan);
        }

        const [driversData, vehiclesData, routeData] = await Promise.all([
          getDrivers(),
          getVehicles(),
          infoRoute,
        ]);

        setDriverData(driversData);
        setVehicleData(vehiclesData);

        if (routeData) {
          manifest.buCodeConsignee = routeData[0].buCodeConsignee ?? "";
          manifest.route = routeData[0].routeName ?? "";
          manifest.routeID = routeData[0].routeID;
        }

        // obtenemos el nombre de la plataforma destino
        if (platFormData) {
          manifest.consigneeName =
            platFormData
              ?.find((x) => x.buCode === manifest.buCodeConsignee)
              ?.buName.toUpperCase() ?? "";
        }

        if (manifest?.consigneeName && manifest?.route) {
          const isEmpty = (value: any): boolean => {
            return value === undefined || value === null || value === "";
          };

          setFormState((prevState: DispatchManifestForm) => {
            const newState: DispatchManifestForm = { ...prevState };

            Object.entries(manifest).forEach(([key, value]) => {
              if (isEmpty((prevState as any)[key])) {
                (newState as any)[key] = value;
              }
            });

            return newState;
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchDispatchData = async () => {
      try {
        // Buscar dentro del local storage el manifiesto
        const responseLocal = manifests.find(
          (x) =>
            x.masterShipmentHeaderCode ===
            manifestFormState.masterShipmentHeaderCode
        );

        let dispatchMani: DispatchManifestForm;
        let manifestDetails: ManifestDetailInterface[] | undefined;

        if (responseLocal) {
          // Construir los objetos necesarios a partir de la data del local storage
          dispatchMani = createObjectDispManiFromManifestHeader(responseLocal);
          manifestDetails = responseLocal.manifestDetail;
        } else {
          // Buscar en el API
          const response = await getManifestPostponedByCode(
            manifestFormState.masterShipmentHeaderCode,
            user.user?.userLogin as string
          );

          if (!response.didError && response.model) {
            dispatchMani = createObjectDispatchManifestForm(response.model);
            manifestDetails = response.model.manifestDetails;
          } else {
            throw new Error("Error fetching data from API");
          }
        }

        // Continuar el proceso con el objeto común
        setFormState((prevState) => {
          const { routeID, route, buCodeConsignee, consigneeName, ...resto } =
            dispatchMani;
          return {
            ...prevState,
            ...resto,
          };
        });

        setManifestFormState((prevState) => ({
          ...prevState,
          masterShipmentHeaderCode: dispatchMani.masterShipmentHeaderCode,
          statusID: dispatchMani.statusID,
        }));

        if (manifestDetails) {
          const dispatchDet = createObjectManifestDetailSingle(
            manifestDetails,
            ManifestType.DESPACHO
          );

          const sortedDispatchDet = dispatchDet.sort((a, b) => {
            const aValue = a.lineSeqNumber ?? -Infinity;
            const bValue = b.lineSeqNumber ?? -Infinity;
            return bValue - aValue;
          });

          await validateIncidenceWarningAllRows(sortedDispatchDet);
          setData(sortedDispatchDet);
          setIsDisabledBtnSave(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const initialize = async () => {
      setBlockUserInput(false);
      setIsLoading(true);
      await fetchData();
      if (shouldFetchData && !!manifestFormState.masterShipmentHeaderCode) {
        await fetchDispatchData();
        await checkPendingValidations();
      }

      setIsLoading(false);
    };

    initialize();
  }, []);

  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  useEffect(() => {
    masterShipmentHeaderIDRef.current = formState.masterShipmentHeaderID;
  }, [formState.masterShipmentHeaderID]);

  useEffect(() => {
    isFetchingRef.current = isFetching;
  }, [isFetching]);

  const checkPendingValidations = async () => {
    if (
      dataRef.current.length > 0 &&
      masterShipmentHeaderIDRef.current &&
      !isFetchingRef.current
    ) {
      const filteredData = createObjectManifestDetail(
        dataRef.current,
        masterShipmentHeaderIDRef.current,
        user.user?.userLogin as string,
        user.businessUnit?.code as string
      );

      let shipmentPendingValidation = dataRef.current.filter(
        (x) => x.incidence === ManifestIncidence.NOT_VALIDATED || !x.incidence
      );

      if (filteredData.length > 0 || shipmentPendingValidation.length > 0) {
        isFetchingRef.current = true;
        setIsFetching(true);

        await updateManifestDetails();
      }

      setIsFetching(false);
      setIsDisabledBtnSave(false);
    }
  };

  useEffect(() => {
    let timerSaveDetail = 5000;
    let intervalId: NodeJS.Timeout | number;

    intervalId = setInterval(checkPendingValidations, timerSaveDetail);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [
    formState.masterShipmentHeaderID,
    user.user?.userLogin,
    user.businessUnit?.code,
  ]);

  // actualizar header del manifiesto, cuando cambie alguno de los valores del header
  useEffect(() => {
    if (isMounted) {
      const updateHeader = async () => {
        const updateManifest: ManifestHeaderUpdateInterface = {
          masterShipmentHeaderID: formState.masterShipmentHeaderID,
          transportID: formState.vehicleID,
          driver1ID: formState.driver1ID,
          seal1: formState.seal1 || "",
          seal2: formState.seal2 || "",
          seal3: formState.seal3 || "",
          masterShipmentHeaderObservation:
            formState.masterShipmentHeaderObservation || "",
          updateUser: user.user?.userLogin || "",
        };

        // actualizo en redux
        handleReduxUpdateManifest(
          updateManifest.masterShipmentHeaderID,
          updateManifest
        );

        // actualizo en BD
        await updateManifestHeader(updateManifest);
      };

      if (formState.masterShipmentHeaderID) {
        if (
          (formState.seal1 !== formState.seal2 &&
            formState.seal1 !== formState.seal3 &&
            formState.seal2 !== formState.seal3) ||
          (formState.seal2 === "N/D" && formState.seal3 === "N/D") ||
          (formState.seal2 === "" && formState.seal3 === "")
        ) {
          updateHeader();
          setIsDisabledBtnSave(false);
        } else {
          alertService.error("No se puede repetir el número de precinto");
          setIsDisabledBtnSave(true);
        }
      } else {
        // valida si el manifiesto no ha sido creado y lo crea y actualiza en redux
        handleCreateManifestHeader(data);
      }
    } else if (formState.masterShipmentHeaderID) {
      setIsMounted(true);
    }
  }, [
    formState.vehicleID,
    formState.driver1ID,
    formState.seal1,
    formState.seal2,
    formState.seal3,
    // formState.masterShipmentHeaderObservation,
  ]);

  // salida del foco del input de shipment 'blur shipment'
  useEffect(() => {
    const blurShipment = async () => {
      // se busca la guia dentro de la lista ya agregada al manifiesto para ver si ya fue buscada en el api
      let shipmentLocal = data?.find(
        (x) => x.shipmentNumber === formState.shipmentNumber
      );
      if (
        shipmentLocal &&
        shipmentDetailEntered?.some(
          (x) => x.shipmentHeaderNumber == shipmentLocal?.shipmentNumber
        )
      ) {
        const ship: ShipmentGuideModel = {
          shipmentDetailID: shipmentLocal?.shipmentDetailID,
          sourceBUCode: shipmentLocal.buCodeSource ?? "",
          serviceCode: parseInt(shipmentLocal.serviceCode) ?? 0,
          serviceName: shipmentLocal.serviceName,
          shipmentNumber: formState.shipmentNumber,
          pieceNumber: 0, // este valor se actualiza en el boton agregar
          locationDestinationCode: shipmentLocal?.locationDestinationCode,
          totalPiece: shipmentLocal.totalPieces ?? 0,
          totalWeigth: 0, // este valor se actualiza en el botpn agregar
        };

        shipmentEntered = ship;
      } else {
        // Buscar el numero de guia en el api
        try {
          const result = await getShipment(formState.shipmentNumber);
          if (result) {
            shipmentEntered = result;
          } else {
            shipmentEntered = null;
            alertService.warn(
              `El número de guía: ${formState.shipmentNumber} no fue encontrado`
            );
          }
        } catch (error) {
          // setError("Error consultando la guía");
        } finally {
          setBlockUserInput(false);
          setIsLoadingBlurShipment(false);
        }
      }
    };

    const callBlurShipment = async () => {
      await blurShipment();
      setBlockUserInput(false);
      setIsLoadingBlurShipment(false);
    };

    if (isLoadingBlurShipment) {
      callBlurShipment();
    }
  }, [isLoadingBlurShipment]);

  // eliminar fila del detalle del manifiesto
  useEffect(() => {
    const deleteRowDetail = async () => {
      const updatedData = data.map((row) =>
        row.shipmentNumber === selectedShipmentNumber
          ? { ...row, statusID: 34 }
          : row
      );

      let updData = createObjectManifestDetail(
        updatedData,
        formState.masterShipmentHeaderID,
        user.user?.userLogin as string,
        user.businessUnit?.code as string,
        false
      );

      let deletedShipmentDetailID = data.find(
        (x) => x.shipmentNumber === selectedShipmentNumber
      )?.containerDetailID;

      if (deletedShipmentDetailID) {
        let resultDeleted = await updateDetailStatusDeleted(
          formState.masterShipmentHeaderID,
          deletedShipmentDetailID,
          user.user?.userLogin as string,
          selectedShipmentNumber
        );

        if (resultDeleted) {
          setData(updatedData);
          handleReduxSetManifestDetails(
            formState.masterShipmentHeaderID,
            updData
          );
        } else {
          alertService.error(
            `Error eliminando la guía nro. ${selectedShipmentNumber} del manifiesto`
          );
        }
      } else {
        setData(updatedData);
        handleReduxSetManifestDetails(
          formState.masterShipmentHeaderID,
          updData
        );
      }
    };

    const callDeleteRowDetail = async () => {
      await deleteRowDetail();
      setBlockUserInput(false);
      setIsLoadingDeleteRows(false);
    };

    if (isLoadingDeleteRows) {
      callDeleteRowDetail();
    }
  }, [isLoadingDeleteRows]);

  const handleReduxUpdateManifest = (
    id: string,
    updatedFields: Partial<ManifestHeaderInterface>
  ) => {
    const manifestToUpdate = manifests.find(
      (manifest: ManifestHeaderInterface) =>
        manifest.masterShipmentHeaderID === id
    );
    if (manifestToUpdate) {
      dispatch(updateManifest({ ...manifestToUpdate, ...updatedFields }));
    }
  };

  // const handleReduxSetManifests = (manifest: ManifestHeaderInterface[]) => {
  //   dispatch(setManifests(manifest));
  // };

  const handleReduxAddManifest = (manifest: ManifestHeaderInterface) => {
    // se debe mantener solo un objeto de manifesto de despacho en el local storage
    // en caso de ya exitir uno, se manda a aliminar
    let removeManifest = manifests.find(
      (x) =>
        x.manifestTypeID === ManifestType.DESPACHO &&
        x.buCodeSource === user.userSession?.buCode
    );

    if (removeManifest) {
      handleReduxRemoveManifest(
        removeManifest.masterShipmentHeaderID as string
      );
    }

    dispatch(addManifest(manifest));
  };

  const handleReduxRemoveManifest = (id: string) => {
    dispatch(removeManifest(id));
  };

  // const handleReduxAddManifestDetail = (
  //   id: string,
  //   detail: ManifestDetailInterface
  // ) => {
  //   dispatch(addManifestDetail({ id, detail }));
  // };

  const handleReduxSetManifestDetails = (
    id: string,
    details: ManifestDetailInterface[]
  ) => {
    dispatch(setManifestDetails({ id, details }));
  };

  // const handleReduxUpdateManifestDetail = (id: string, detail: ManifestDetailInterface) => {
  //   dispatch(updateManifestDetail({ id, detail }));
  // };

  // const handleReduxRemoveManifestDetail = (id: string, detailID: string) => {
  //   dispatch(removeManifestDetail({ id, detailID }));
  // };

  const handleReduxAddInfoManifest = (infoManif: InfoDataManifest) => {
    let removeInfoManifest = manifestInfoBasic?.find(
      (x) => x.buSource === user.businessUnit?.code
    );

    if (!removeInfoManifest) {
      dispatch(addDataBasicManifest(infoManif));
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const handleVehicleSelect = (vehicle: Vehicles) => {
    if (vehicle.transportID) formState.vehicleID = vehicle.transportID;
  };

  const handleDriverSelect = (driver: Driver) => {
    if (driver.accountID) formState.driver1ID = driver.accountID;
  };

  const getShipment = async (
    shipmentNumber: string
  ): Promise<ShipmentGuideModel | null> => {
    setError(null); // Reset error state

    try {
      if (shipmentNumber) {
        const shipmentResp = await getOfficeShipment(shipmentNumber);

        if (shipmentResp) {
          shipmentResp.shipmentDetail.forEach((shipment) => {
            shipment.shipmentHeaderNumber = shipmentNumber;
          });

          const resp: ShipmentGuideModel = {
            shipmentDetailID: shipmentResp.shipmentDetail[0]?.shipmentDetailID,
            sourceBUCode: shipmentResp.buCodeSource ?? "",
            serviceCode: parseInt(shipmentResp.serviceCode) ?? 0,
            serviceName:
              shipmentResp.serviceShortName?.toUpperCase() ||
              shipmentResp.serviceDescription?.toUpperCase() ||
              "",
            shipmentNumber: shipmentNumber,
            pieceNumber: 0, // este valor se actualiza en el boton agregar
            locationDestinationCode: shipmentResp.locationConsigneeCode ?? "",
            totalPiece: shipmentResp.totalPieces ?? 0,
            totalWeigth: 0, // este valor se actualiza en el botpn agregar
          };

          // Crear una nueva copia del arreglo
          // const newShipmentDetails = shipmentDetailEntered ? [...shipmentDetailEntered, shipmentResp.shipmentDetail] : [shipmentResp.shipmentDetail];

          // guardamos el detalle de piezas de la guia
          // setShipmentDetailEntered(
          //   newShipmentDetails as ShipmentDetailResumen[]
          // );
          if (shipmentResp?.shipmentDetail.length > 0) {
            shipmentDetailEntered.push(...shipmentResp.shipmentDetail);
          }

          return resp;
        } else {
          shipmentEntered = null;
          setError(`El número de guía: ${shipmentNumber} no fue encontrado`);
        }
      }
    } catch (e) {
      shipmentEntered = null;
      setError(
        `Error al obtener los detalles de la guía nro.: ${shipmentNumber}`
      );
    }

    return null;
  };

  const handleBlurShipment = async () => {
    if (formState.shipmentNumber.length > 4) {
      setBlockUserInput(true);
      setIsLoadingBlurShipment(true);
    }
  };

  const createManifestShipment = (
    shipment: ShipmentGuideModel
  ): ManifestDetailSingleInterface => {
    let serviceShipment = services.find(
      (x) => x.serviceCode === shipment.serviceCode.toString()
    );
    return {
      shipmentDetailID: shipment.shipmentDetailID,
      containerDetailID: null,
      shipmentNumber: shipment.shipmentNumber,
      buCodeSource: shipment.sourceBUCode,
      serviceCode: shipment.serviceCode.toString(),
      serviceID: serviceShipment?.serviceID,
      serviceName:
        serviceShipment?.serviceShortName?.toUpperCase() ||
        serviceShipment?.serviceDescription?.toUpperCase() ||
        "",
      pieceNumber: shipment.pieceNumber,
      locationDestinationCode: shipment.locationDestinationCode,
      totalPieces: shipment.totalPiece,
      manifestType: ManifestType.DESPACHO,
      physicalWeight: shipment.totalWeigth ?? 0,
      creationDate: shipment.creationDate ?? "",
      daysCreation: 0,
      difference: 0,
      incidence: ManifestIncidence.NOT_VALIDATED,
      incidenceWarning: false,
      warnings: "",
      errors: "",
    };
  };

  const handleClickManifestDraft = async () => {
    if (data.length > 0) {
      if (
        // data.some((x) => !x.containerDetailID) ||
        !formState.masterShipmentHeaderID
      ) {
        alertService.warn(
          "Valide su conexión a internet, el manifiesto no puede ser postergado"
        );
      } else {
        // let result = await postponeManifest(
        //   formState.masterShipmentHeaderID,
        //   user.user?.userLogin as string
        // );

        // if (!result?.didError) {
        alertService.success(
          "Manifiesto de despacho",
          `El manifiesto '${formState.masterShipmentHeaderCode}' ha sido postergado exitosamente`
        );
        setTimeout(() => {
          window.location.reload();
        }, 2500);
        // } else {
        //   alertService.error(
        //     "Error guardando el manifiesto, por favor vuelva a intentarlo"
        //   );
        // }
      }
    } else {
      alertService.error("No se puede guardar un manifiesto sin detalles");
    }
  };

  const handleBarcodeValueChange = async (value: ShipmentGuideModel | null) => {
    if (value) {
      const isValid = await isValidManifestHeader();
      if (isValid) {
        isManualShipmentEntry = false;
        let newBarCode = createManifestShipment(value);
        handleAddRowManifest(newBarCode);
      } else {
        alertService.warn(
          "Debe seleccionar el vehículo, conductor y precinto 1"
        );
      }
    }
  };

  const isValidManifestHeader = async (): Promise<boolean> => {
    // Simula una validación asíncrona si es necesario
    return new Promise((resolve) => {
      if (
        formState.driver1ID !== "" &&
        formState.vehicleID !== "" &&
        formState.seal1 !== "" &&
        formState.routeID &&
        formState.routeID > 0 &&
        formState.buCodeConsignee !== ""
      ) {
        resolve(true);
      } else resolve(false);
    });
  };

  const handleAddShipment = (value: ShipmentGuideModel | null) => {
    if (value) {
      isManualShipmentEntry = true;
      // actualizo el numero de pieza con los datos del formulario
      value.pieceNumber = formState.piece;
      let newBarCode = createManifestShipment(value);
      handleAddRowManifest(newBarCode);
    }
  };

  const handleCreateManifestHeader = async (
    value: ManifestDetailSingleInterface[]
  ) => {
    if (
      !formState.masterShipmentHeaderID &&
      value.some((x) => x.shipmentNumber)
    ) {
      let manifestHeader = createObjectManifestHeader(
        formState,
        user.user?.userLogin as string
      );

      // guardar en BD
      let newManifestHeader = await createManifestHeader(manifestHeader);

      if (
        !newManifestHeader.didError &&
        newManifestHeader.model?.masterShipmentHeaderID &&
        newManifestHeader.model?.masterShipmentHeaderCode
      ) {
        formState.masterShipmentHeaderID =
          newManifestHeader.model.masterShipmentHeaderID;
        formState.masterShipmentHeaderCode =
          newManifestHeader.model.masterShipmentHeaderCode;
        formState.statusID = newManifestHeader.model.statusID;

        let driverSelect = driverData?.find(
          (x) => x.accountID === newManifestHeader.model?.driver1ID
        );
        let transportSelect = vehicleData?.find(
          (x) => x.transportID === newManifestHeader.model?.transportID
        );

        newManifestHeader.model.driver1IdentificationNumber =
          driverSelect?.identificationNumber;
        newManifestHeader.model.driver1Name = driverSelect?.accountFullName;
        newManifestHeader.model.transportModelName =
          transportSelect?.transportModel;
        newManifestHeader.model.transportPlate =
          transportSelect?.transportPlate;
        newManifestHeader.model.statusName =
          ManifestStatus[newManifestHeader.model?.statusID ?? 33];

        // guardar en redux el manifestHeader
        handleReduxAddManifest(newManifestHeader.model);

        setManifestFormState((prevState) => ({
          ...prevState,
          masterShipmentHeaderCode:
            newManifestHeader?.model?.masterShipmentHeaderCode ?? "",
          statusID: newManifestHeader?.model?.statusID,
        }));
      } else {
        setMessageErrorCreateManifest(
          `Error creando el manifiesto ¡¡ ${newManifestHeader.message} !!. Por favor valide los datos`
        );
        setOpenErrorCreateManifestHeader(true);
      }

      // se crea el objeto para guardar en redux
      let newRowDetail = createObjectManifestDetail(
        value,
        formState.masterShipmentHeaderID,
        user.user?.userLogin as string,
        user.businessUnit?.code as string,
        false
      );

      // guardar en redux el detail
      handleReduxSetManifestDetails(
        formState.masterShipmentHeaderID,
        newRowDetail
      );

      // activo el useState para que al modficar el precinto se pueda mandar a crear el manifestHeader
      setIsMounted(true);
    }
  };

  const handleAddRowManifest = async (
    newRow: ManifestDetailSingleInterface
  ) => {
    try {
      setIsDisabledBtnSave(true);

      let existShipment = data.some(
        (x) =>
          x.shipmentNumber === newRow.shipmentNumber &&
          x.pieceNumber === Number(newRow.pieceNumber) &&
          x.statusID !== 34
      );

      if (!existShipment) {
        if (
          !newRow.shipmentNumber ||
          !newRow.serviceCode ||
          !newRow.locationDestinationCode
        ) {
          alertService.warn(
            "Todos los datos de la guía son obligatorio " +
              newRow.shipmentNumber +
              "-" +
              newRow.serviceCode +
              "-" +
              newRow.locationDestinationCode
          );
          return;
        }
      } else {
        alertService.warn(
          `La guía nro. ${newRow.shipmentNumber} y la pieza ${newRow.pieceNumber} ya se encuentran agregado, por favor valide los datos`
        );
        return;
      }

      if (isManualShipmentEntry) {
        var foundItem = shipmentDetailEntered?.find(
          (x) =>
            x.shipmentHeaderNumber === formState.shipmentNumber &&
            x.pieceNumber === Number(formState.piece)
        );

        if (foundItem) {
          newRow.pieceNumber = foundItem.pieceNumber;
          newRow.physicalWeight = foundItem.physicalWeight;
        } else {
          alertService.error(
            `No se encontró la pieza número: ${formState.piece}`
          );
          return;
        }
      }

      newRow.lineSeqNumber = data.length + 1;

      // conteo de la nueva guia, si ya existe en el data
      const countShipmentNumber = data.filter(
        (row) =>
          row.shipmentNumber === newRow.shipmentNumber && row.statusID !== 34
      ).length;

      // Actualizar la propiedad incidenceWarning de newRow
      newRow.incidenceWarning =
        newRow.totalPieces === countShipmentNumber + 1 ? false : true;
      let faltan = newRow.totalPieces - (countShipmentNumber + 1);
      newRow.warnings = newRow.incidenceWarning
        ? `El número de guía ${newRow.shipmentNumber}, tiene ${faltan} ${
            faltan === 1 ? `pieza faltante.` : `piezas faltantes.`
          }`
        : "";

      // Actualizar todas las filas con el mismo número de guía
      const updatedData = data.map((row) => {
        if (row.shipmentNumber === newRow.shipmentNumber) {
          return {
            ...row,
            incidenceWarning: newRow.incidenceWarning,
            warnings: newRow.warnings,
          };
        }
        return row;
      });

      // Actualizar el estado de data
      setData((prevData) => [newRow, ...updatedData]);

      // valida si el manifiesto no ha sido creado y lo crea y actualiza en redux
      await handleCreateManifestHeader([newRow, ...updatedData]);

      // se crea el objeto para guardar en redux
      let newRowDetail = createObjectManifestDetail(
        [newRow, ...updatedData],
        formState.masterShipmentHeaderID,
        user.user?.userLogin as string,
        user.businessUnit?.code as string,
        false
      );

      // guardar en redux el detail
      handleReduxSetManifestDetails(
        formState.masterShipmentHeaderID,
        newRowDetail
      );

      setFormState({
        ...formState,
        shipmentNumber: "",
        piece: 0,
      });

      shipmentEntered = null;

      setIsFetching(false);
    } catch (error) {
      alertService.error("Ocurrió un error al agregar el registro.");
    }
  };

  const validateIncidenceWarningAllRows = async (
    details: ManifestDetailSingleInterface[]
  ) => {
    let updatedData = [...details];

    // Iterar sobre cada fila en data
    updatedData = await Promise.all(
      updatedData.map(async (row) => {
        // Conteo de la guía actual
        const countShipmentNumber = details.filter(
          (r) => r.shipmentNumber === row.shipmentNumber && r.statusID !== 34
        ).length;

        // Actualizar la propiedad incidenceWarning de la fila actual
        row.incidenceWarning =
          row.totalPieces === countShipmentNumber ? false : true;
        let faltan = row.totalPieces - countShipmentNumber;
        row.warnings = row.incidenceWarning
          ? `El número de guía ${row.shipmentNumber}, tiene ${faltan} ${
              faltan === 1 ? `pieza faltante.` : `piezas faltantes.`
            }`
          : "";

        return row;
      })
    );

    // Actualizar el estado de data
    setData(updatedData);
  };

  const handleRowClickManifestDetail = (shipmentNumber: string) => {
    const occurrences = data.filter(
      (row) => row.shipmentNumber === shipmentNumber
    ).length;

    const allValidated = data
      .filter((x) => x.shipmentNumber === shipmentNumber)
      .every((x) => x.incidence !== ManifestIncidence.NOT_VALIDATED);

    if (occurrences > 0 && allValidated) {
      const messageRowManifestDelete =
        occurrences === 1
          ? `¿Estás seguro de eliminar la guía nro. ${shipmentNumber}?`
          : `Esta guía nro. ${shipmentNumber}, ha agregado ${occurrences} piezas. ¿Deseas eliminar todos los registros de esta guía?`;

      setSelectedShipmentNumber(shipmentNumber);
      setMessageRowManifestDelete(messageRowManifestDelete);
      setOpenClickRowManifestDetailModal(true);
    } else if (occurrences > 0) {
      alertService.warn(
        `El número de guía ${shipmentNumber} se está validando, por favor espere...`
      );
    }
  };

  const handleAcceptClickRowsManifestDelete = async () => {
    setOpenClickRowManifestDetailModal(false);

    setBlockUserInput(true);
    setIsLoadingDeleteRows(true);
  };

  // variables y logica para modal de limpiar y finalizar
  // Filtra los errores y warnings
  const processedGuia = new Set<string>();

  const errors = data
    .filter((x) => x.errors !== "" && x.statusID !== 34)
    .filter((x) => {
      if (!processedGuia.has(x.shipmentNumber)) {
        processedGuia.add(x.shipmentNumber);
        return true;
      }
      return false;
    })
    .map((x) => x.errors)
    .join("\n");

  const processedGuiaWarnings = new Set<string>();

  const warnings = data
    .filter((x) => x.warnings !== "" && x.statusID !== 34)
    .filter((x) => {
      if (!processedGuiaWarnings.has(x.shipmentNumber)) {
        processedGuiaWarnings.add(x.shipmentNumber);
        return true;
      }
      return false;
    })
    .map((x) => x.warnings)
    .join("\n");

  const finalWarningMessage = warnings
    ? `${warnings} \n¿Está seguro de finalizar y cerrar el manifiesto?`
    : "¿Está seguro de finalizar y cerrar el manifiesto?";

  // Determina si hay errores
  const hasErrors = errors.length > 0;
  const hasWarning = warnings.length > 0;

  // Configura el mensaje, ícono y botones del modal
  const message = hasErrors ? errors : finalWarningMessage;
  const iconType = hasErrors ? "error" : hasWarning ? "warning" : "question";
  const showCancelButton = !hasErrors;

  //manejador para boton de modal de generar manifiesto
  const handleClickGenerateManifest = async () => {
    if (data.length > 0) {
      if (
        data.some((x) => !x.containerDetailID) ||
        !formState.masterShipmentHeaderID
      ) {
        alertService.warn("Espere... existen detalles pendiente por guardar");
      } else {
        // se abre la modal
        setOpenGenerateManifestModal(true);
      }
    } else {
      alertService.warn("No se puede guardar un manifiesto sin detalles");
    }
  };

  const handleAcceptGenerate = async () => {
    // si existen errores, solo se cierra la modal
    if (hasErrors) {
      setOpenGenerateManifestModal(false);
    } else {
      // Lógica para generar el manifiesto
      let result = await generateManifest(
        formState.masterShipmentHeaderID,
        user.user?.userLogin as string,
        formState.masterShipmentHeaderObservation || ""
      );

      if (!result?.didError) {
        // se manda a limpiar el manifiesto en el localStorage
        handleReduxRemoveManifest(formState.masterShipmentHeaderID);
        setOpenSuccessGenerate(true);

        // alertService.success(
        //   `El manifiesto '${formState.masterShipmentHeaderCode}' ha sido generado exitosamente`
        // );
        // setTimeout(() => {
        //   window.location.reload();
        // }, 3000);
      } else {
        alertService.error(
          "Error generando el manifiesto, por favor vuelva a intentarlo"
        );
      }

      setOpenGenerateManifestModal(false);
    }
  };

  // aceptar modal de limpiar
  const handleAcceptClearManifest = async () => {
    // actualizar el estatus del manifiesto que se esta descartando. 'Inactivarlo'
    if (formState.masterShipmentHeaderID) {
      await updateManifestStatus(
        formState.masterShipmentHeaderID,
        ManifestStatus.INACTIVO,
        user.user?.userLogin as string
      );
    }

    let manifestIni: DispatchManifestForm = {
      masterShipmentHeaderID: "",
      masterShipmentHeaderCode: "",
      route: formState.route || "",
      routeID: formState.routeID || 0,
      consigneeName: formState.consigneeName || "",
      buCodeConsignee: formState.buCodeConsignee || "",
      vehicle: "",
      vehicleID: "",
      transportID: "",
      transportModelName: "",
      transportPlate: "",
      driver: "",
      driver1ID: "",
      driver1IdentificationNumber: "",
      driver1Name: "",
      seal1: "",
      seal2: "",
      seal3: "",
      shipmentNumber: "",
      piece: 0,
      buCodeSource: formState.buCodeSource || "",
      buSource: formState.buSource || "",
      manifestType: ManifestType.DESPACHO,
      masterShipmentHeaderObservation: "",
      totalShipments: 0,
      totalPieces: 0,
      masterShipmentHeaderIDDispatch: "",
      masterShipmentHeaderCodeDispatch: "",
    };

    // limpiar el manifiesto de redux
    handleReduxRemoveManifest(formState.masterShipmentHeaderID);

    // limpiar los imputs
    setFormState(manifestIni);

    // limpiar barcode mediante ref
    if (barcodeRef.current) {
      barcodeRef.current.clearBarcode(); // Llamar al método para limpiar el campo de código de barras
    }
    // limpiar los detalles del manifiesto
    setData([]);

    setOpenClearManifestModal(false);
  };

  return (
    <>
      <div className="flex flex-1 flex-col gap-2 max-w-screen-2xl mb-4">
        {/* <form onSubmit={handleSubmit} className="space-y-4"> min-w-[60rem]*/}
        <form onSubmit={handleSubmit}>
          {/* Header - datos principal del manifiesto*/}
          <div className="flex flex-col gap-8 mb-4">
            <div className="flex flex-col bg-white gap-4 rounded-lg border px-8 pb-6 pt-4">
              <div className="flex flex-col sm:flex-row w-full gap-4">
                <div className="w-full sm:w-1/4">
                  <label className="block text-m font-medium leading-6 text-gray-800 mb-2 truncate">
                    Ruta
                  </label>
                  <FormTextInput
                    id="route"
                    name="route"
                    type="text"
                    onChange={handleChange}
                    readOnly
                    value={formState.route}
                  />
                </div>
                <div className="w-full sm:w-1/4">
                  <label className="block text-m font-medium leading-6 text-gray-800 mb-2 truncate">
                    Plataforma
                  </label>
                  <FormTextInput
                    id="platform"
                    name="platform"
                    type="text"
                    onChange={handleChange}
                    readOnly
                    value={formState.consigneeName}
                  />
                </div>
                <div className="w-full sm:w-1/4">
                  <label className="block text-m font-medium leading-6 text-gray-800 mb-2 truncate">
                    Vehículo
                  </label>
                  <VehicleSearch
                    id="vehicle"
                    name="vehicle"
                    vehicles={vehicleData ?? []}
                    onVehicleSelect={handleVehicleSelect}
                    initialValue={formState.vehicleID}
                  />
                </div>
                <div className="w-full sm:w-1/4">
                  <label className="block text-m font-medium leading-6 text-gray-800 mb-2 truncate">
                    Conductor
                  </label>
                  <DriverSearch
                    id="driver"
                    name="driver"
                    drivers={driverData ?? []}
                    onDriverSelect={handleDriverSelect}
                    initialValue={formState.driver1ID}
                  />
                </div>
              </div>
              <div className="flex flex-col sm:flex-row gap-4">
                <div className="flex flex-col sm:flex-row w-full sm:w-1/2 gap-4">
                  <div className="w-full sm:w-1/3">
                    <label className="block text-m font-medium leading-6 text-gray-800 mb-2 truncate">
                      Precinto 1
                    </label>
                    <FormTextInput
                      id="seal1"
                      // ref={inputSeal1Ref}
                      name="seal1"
                      type="text"
                      value={formState.seal1}
                      onChange={handleChange}
                      placeholder="Introduzca el precinto 1"
                      maxLength={25}
                      className="uppercase placeholder-normalcase"
                    />
                    <style>{`.placeholder-normalcase::placeholder {
                        text-transform: none;}`}</style>
                  </div>
                  <div className="w-full sm:w-1/3">
                    <label className="block text-m font-medium leading-6 text-gray-800 mb-2 truncate">
                      Precinto 2
                    </label>
                    <FormTextInput
                      id="seal2"
                      name="seal2"
                      type="text"
                      value={formState.seal2}
                      onChange={handleChange}
                      placeholder="Introduzca el precinto 2"
                      maxLength={25}
                      className="uppercase placeholder-normalcase"
                    />
                  </div>
                  <div className="w-full sm:w-1/3">
                    <label className="block text-m font-medium leading-6 text-gray-800 mb-2 truncate">
                      Precinto 3
                    </label>
                    <FormTextInput
                      id="seal3"
                      name="seal3"
                      type="text"
                      value={formState.seal3}
                      onChange={handleChange}
                      placeholder="Introduzca el precinto 3"
                      maxLength={25}
                      className="uppercase placeholder-normalcase"
                    />
                  </div>
                </div>
                <div className="w-full sm:w-1/2">
                  <label className="block text-m font-medium leading-6 text-gray-800 mb-2 truncate">
                    Observación
                  </label>
                  <FormTextInput
                    id="masterShipmentHeaderObservation"
                    name="masterShipmentHeaderObservation"
                    type="text"
                    value={formState.masterShipmentHeaderObservation}
                    onChange={handleChange}
                    placeholder="Introduzca la observación"
                    maxLength={150}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Body - ingresar datos de guias */}
          <div className="flex flex-1 flex-col gap-8 mb-2">
            <div className="flex flex-col sm:flex-row bg-white gap-4 rounded-lg border px-8 pb-6 pt-4">
              <div className="w-full sm:w-3/4">
                <label className="block text-m font-medium leading-6 text-gray-800 mb-2 truncate">
                  Código de Barra
                </label>
                <BarcodeScannerComponent
                  ref={barcodeRef}
                  onValueChange={handleBarcodeValueChange}
                  maxLength={30}
                />
              </div>
              <div className="w-full sm:w-2/5">
                <label className="block text-m font-medium leading-6 text-gray-800 mb-2 truncate">
                  Número de Guía
                </label>
                <NumericInput
                  id="shipmentNumber"
                  name="shipmentNumber"
                  placeholder="Introduzca el número de guía"
                  value={formState.shipmentNumber}
                  onChange={handleChange}
                  onBlur={handleBlurShipment}
                  maxLength={20}
                />
              </div>
              <div className="flex w-full sm:w-1/3">
                <div className="sm:w-full">
                  <label className="block text-m font-medium leading-6 text-gray-800 mb-2 truncate">
                    Número de Pieza
                  </label>
                  <FormTextInput
                    id="piece"
                    name="piece"
                    type="number"
                    value={formState.piece}
                    onChange={handleChange}
                    min={0}
                    max={999}
                    placeholder="Introduzca el número de pieza"
                  />
                </div>
                <div className="w-1/2 ml-4 mt-8">
                  <PrimaryButton
                    id="btnShipmentAdd"
                    type="button"
                    className="
              px-3 py-2"
                    onClick={() => {
                      if (shipmentEntered) {
                        handleAddShipment(shipmentEntered);
                      } else if (
                        !formState.shipmentNumber ||
                        !formState.piece
                      ) {
                        alertService.warn(
                          `Debe ingresar el número de guía y la pieza`
                        );
                        // if (!!shipmentNumberRef.current) {
                        //   shipmentNumberRef.current?.focus();
                        // }
                      } else {
                        alertService.warn(
                          `El número de guía ${formState.shipmentNumber} no fue encontrado`
                        );
                      }
                    }}
                  >
                    Agregar
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </div>
          {/* </Formik> */}
        </form>
        {/* Body - tabla de guias cargadas en manifiesto*/}
        <div className="flex flex-1 flex-col gap-8 mb-8">
          <div className="flex flex-1 flex-row bg-white gap-4 rounded-lg border px-8 pb-4">
            <ManifestDetailsTable
              data={data}
              onAdd={handleAddRowManifest}
              onRowClick={handleRowClickManifestDetail}
              showColumStatus={true}
            />
          </div>
        </div>
        {/* </Footer botones> */}
        <div className="flex flex-1 flex-row px-8 mb-6 justify-end mr-4">
          <div className="ml-2 mt-4 justify-end">
            <PrimaryButton
              className="
              px-3 py-2"
              onClick={() => setOpenClearManifestModal(true)}
            >
              Limpiar todo
            </PrimaryButton>
          </div>
          <div className="ml-2 mt-4 justify-end">
            <PrimaryButton
              disabled={isDisabledBtnSave}
              className="
              px-3 py-2"
              onClick={handleClickManifestDraft}
            >
              Continuar luego
            </PrimaryButton>
          </div>
          <div className="ml-2 mt-4 justify-end">
            <PrimaryButton
              disabled={isDisabledBtnSave}
              className="
              px-3 py-2"
              onClick={handleClickGenerateManifest}
            >
              Finalizar y cerrar
            </PrimaryButton>
          </div>
        </div>
      </div>

      {/* Modal de consulta, si desea limpiar todo el manifiesto */}
      <ReusableModal
        openModal={openClearManifestModal}
        setOpenModal={setOpenClearManifestModal}
        title="Creación de despacho"
        message={
          "¿Estás seguro de querer borrar los datos del manifiesto? Esta acción es irreversible"
        }
        iconType="question"
        onCancel={() => setOpenClearManifestModal(false)}
        onAccept={handleAcceptClearManifest}
        showCancelButton={true}
        cancelButtonText="Cancelar"
        acceptButtonText="Aceptar"
      />
      {/* Modal de consulta, si desea finalizar y cerrar el manifiesto 'GENERAR' */}
      <ReusableModal
        openModal={openGenerateManifestModal}
        setOpenModal={setOpenGenerateManifestModal}
        title="Creación de despacho"
        message={message}
        iconType={iconType}
        onCancel={() => setOpenGenerateManifestModal(false)}
        onAccept={handleAcceptGenerate}
        showCancelButton={showCancelButton}
        cancelButtonText="Cancelar"
        acceptButtonText="Aceptar"
      />
      {/* Modal al dar click en el table de datelle del manifiesto, para eliminar la guia */}
      <ReusableModal
        openModal={openClickRowManifestDetailModal}
        setOpenModal={setOpenClickRowManifestDetailModal}
        title="Creación de despacho"
        message={messageRowManifestDelete}
        iconType="question"
        onCancel={() => setOpenClickRowManifestDetailModal(false)}
        onAccept={handleAcceptClickRowsManifestDelete}
        showCancelButton={true}
        cancelButtonText="Cancelar"
        acceptButtonText="Aceptar"
      />

      {/* Modal al generar el manifiesto */}
      <ReusableModal
        openModal={openSuccessGenerate}
        setOpenModal={setOpenSuccessGenerate}
        title="Creación de despacho"
        message={`El manifiesto '${formState.masterShipmentHeaderCode}' ha sido generado exitosamente`}
        iconType="success"
        // onCancel={() => setOpenSuccessGenerate(false)}
        onAccept={handleAcceptSuccessManifest}
        showCancelButton={false}
        cancelButtonText="Cancelar"
        acceptButtonText="Aceptar"
      />

      {/* Modal de error creando manifest header */}
      <ReusableModal
        openModal={openErrorCreateManifestHeader}
        setOpenModal={setOpenErrorCreateManifestHeader}
        title="Creación de despacho"
        message={messageErrorCreateManifest}
        iconType="error"
        // onCancel={() => setOpenErrorCreateManifestHeader(false)}
        onAccept={handleAcceptErrorGenerarManifest}
        showCancelButton={false}
        cancelButtonText="Cancelar"
        acceptButtonText="Aceptar"
      />

      <LoadingModal
        openModal={isLoadingBlurShipment}
        setOpenModal={setIsLoadingBlurShipment}
        blockUserInput={blockUserInput}
      />

      <LoadingModal
        openModal={isLoading}
        setOpenModal={setIsLoading}
        blockUserInput={blockUserInput}
      />

      <LoadingModal
        openModal={isLoadingDeleteRows}
        setOpenModal={setIsLoadingDeleteRows}
        blockUserInput={blockUserInput}
      />
    </>
  );
};

export default NewDispatch;
