import api from "./api";
import { ResponseInterface } from "../interfaces";
import { LiabilityWaiverDTO } from "../interfaces/Dtos/liabilityWaiverDTO";

const baseURLAPI = process.env.REACT_APP_API_HOST;

// Add AM to service
export const saveLiabilityWaver = async (
  fingerprint: File | undefined,
  photoAttachment: File | undefined,
  shipmentHeaderIDList: string[],
  isPhysicalLiabilityWaiver: boolean,
  signatoryName: string,
  signatoryIdentification: string,
  creationUser: string,
  statusID: number
): Promise<ResponseInterface<boolean>> => {
  const uri = `${baseURLAPI}/LiabilityWaiver`;
  const formData = new FormData();
  if (fingerprint) {
    const fingerprintBlob = new Blob([fingerprint], { type: "image/png" });
    formData.append("Fingerprint", fingerprintBlob, "fingerprint.png");
  }
  if (photoAttachment) formData.append("PhotoAttachment", photoAttachment);
  formData.append("ShipmentHeaderIDList", shipmentHeaderIDList.join(","));
  formData.append(
    "IsPhysicalLiabilityWaiver",
    isPhysicalLiabilityWaiver.toString()
  );
  formData.append("SignatoryName", signatoryName);
  formData.append("SignatoryIdentification", signatoryIdentification);
  formData.append("CreationUser", creationUser);
  formData.append("StatusID", statusID.toString());

  try {
    const response = await api.post(uri, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    return (error as any)?.response?.data;
  }
};

export const searchLiabilityWaiver = async ({
  liabilityWaverID = "",
  shipmentHeaderID = "",
  shipperFingerPrintAttchID = "",
}: {
  liabilityWaverID?: string;
  shipmentHeaderID?: string;
  shipperFingerPrintAttchID?: string;
}): Promise<ResponseInterface<Array<LiabilityWaiverDTO>>> => {
  const uri = `${baseURLAPI}/LiabilityWaiver?LiabilityWaverID=${liabilityWaverID}&ShipmentHeaderID=${shipmentHeaderID}&ShipperFingerPrintAttchID=${shipperFingerPrintAttchID}`;
  try {
    const response = await api.get(uri);
    return response.data;
  } catch (error) {
    return (error as any)?.response?.data;
  }
};
