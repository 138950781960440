import { FC, useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { forEach } from "lodash";
import { useNavigate } from "react-router-dom";
import { clearShipmentCreate } from "../../store/slices";
import { PaymentMode, ShipmentInterface } from "../../interfaces";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { DocumentIcon, TagIcon } from "@heroicons/react/24/outline";
import { ClipboardDocumentCheckIcon } from "@heroicons/react/24/solid";
import { OriginModal } from "../../components/Shipment/OriginModal";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons";
import { saveLiabilityWaver } from "../../services/liabilityWaiverServices";
import {
  formatName,
  currencyExchangeText,
  useCurrencyExchanges,
  base64ToFile,
} from "../../utils";
import {
  PrintShipmentLabel,
  ShipmentDeclaredDocumentRequestDto,
  alertService,
  getAllDeclaredShipmentDocuments,
  getDeclaredShipmentDocument,
  loaderService,
} from "../../services";

interface ShipmentItemProps {
  index: number;
  shipment: ShipmentInterface;
}
const ShipmentItem: FC<ShipmentItemProps> = ({ index, shipment }) => {
  const exchanges = useCurrencyExchanges();
  const handleDownloadDocument = async (shipment: ShipmentInterface) => {
    loaderService.start();
    alertService.info("Imprimiendo documento...");
    const fileResult = await getDeclaredShipmentDocument({
      shipmentNumber: shipment.shipmentNumber ?? "",
      shipmentHeaderID: shipment.id,
    });
    loaderService.stop();
    if (!!fileResult) {
      // Create a URL for the Blob
      const fileURL = URL.createObjectURL(fileResult.file);

      // Create a link element
      const a = document.createElement("a");
      a.href = fileURL;
      a.download = fileResult.fileName; // Set the filename for the downloaded file
      a.click();

      /*const printWindow = window.open(fileURL, "_blank");
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.print();
        };
      } else {
        console.error("Could not open print window");
      }*/
      // Remember to handle Blob URL cleanup when done
      URL.revokeObjectURL(fileURL);
      alertService.success("Documento impreso satisfactoriamente.");
    } else {
      alertService.error("No se pudo descargar el documento de la guía.");
    }
  };

  const handlePrintLabel = async (shipment: ShipmentInterface) => {
    if (!shipment.shipmentNumber || !shipment.service) {
      alertService.error(
        "Error al imprimir la etiqueta",
        "No se encontraron los identificadores de la ."
      );
      return;
    }
    loaderService.start();
    alertService.info(
      "Guía: " + shipment.shipmentNumber,
      "Imprimiendo etiqueta..."
    );
    const printSucceed = await PrintShipmentLabel(
      +shipment.shipmentNumber,
      shipment.service ?? 0,
      shipment.buSource.code
    );
    loaderService.stop();
    if (!!printSucceed) {
      alertService.success(
        "Guía:" + shipment.shipmentNumber,
        "La etiqueta se imprimió correctamente."
      );
    }
  };

  return (
    <tr
      className={classNames(
        "hover:bg-gray-100",
        index % 2 === 0 && "bg-gray-50"
      )}
    >
      <td className="text-xs px-4 py-4 font-bold text-gray-500 truncate text-left">
        {shipment.shipmentNumber}
      </td>

      <td className="text-xs px-4 py-4 font-semibold text-gray-500 truncate text-left truncate">
        {formatName(shipment.consignee.accountFullName)}
      </td>

      <td className="text-xs px-4 py-4 font-semibold w-auto text-gray-500 truncate">
        {!!shipment.buConsignee?.code ? `${shipment.buConsignee?.code} -` : ""}
        {shipment.consigneeAddress.name}
      </td>

      <td className="text-right text-xs px-4 py-4 font-semibold w-auto text-gray-500 truncate">
        {currencyExchangeText(shipment.total, exchanges, "USD")}
      </td>

      <td className="text-xs px-4 py-4 flex items-end justify-end gap-2">
        <div
          title="Imprimir Etiqueta"
          data-te-toggle="tooltip"
          className="flex justify-center rounded-full w-9 bg-indigo-100 hover:bg-indigo-200 hover:cursor-pointer p-2"
          onClick={() => handlePrintLabel(shipment)}
        >
          <TagIcon className="h-5 w-5 text-indigo-600" />
        </div>

        <div
          title="Imprimir Documento"
          data-te-toggle="tooltip"
          className="flex justify-center rounded-full w-9 bg-indigo-100 hover:bg-indigo-200 hover:cursor-pointer p-2"
          onClick={() => handleDownloadDocument(shipment)}
        >
          <DocumentIcon className="h-5 w-5 text-indigo-600" />
        </div>
      </td>
    </tr>
  );
};

const ShipmentResume: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const documentOrder = useAppSelector(
    (state) => state.shipmentCreate.document
  );
  const shipments = useAppSelector((state) => state.shipmentCreate.shipments);
  const [openModal, setOpenModal] = useState(true);
  const [fingerprint, setFingerprint] = useState<string | undefined>();
  const [photo, setPhoto] = useState<string | undefined>();
  const [signatoryName, setSignatoryName] = useState<string>("");
  const [signatoryId, setSignatoryId] = useState<string>("");
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const paymentMode = useMemo(() => {
    return shipments[0]?.paymentMode;
  }, [shipments]);

  const handleFinish = useCallback(async () => {
    const liabilityWaiver = await onSaveLiabilityWaver();
    if (liabilityWaiver.didError) {
      alertService.error(liabilityWaiver.errorMessage);
      return;
    }

    dispatch(clearShipmentCreate());

    // Redirect to document detail
    if (
      paymentMode === PaymentMode.CONTADO ||
      paymentMode === PaymentMode.BOXOFFICE_CREDIT
    ) {
      navigate(`/documents/${documentOrder?.documentID}`);
    } else {
      navigate(`/shipments/${shipments[0].shipmentNumber}`);
    }
  }, [
    navigate,
    dispatch,
    paymentMode,
    shipments,
    documentOrder,
    fingerprint,
    photo,
    checkboxChecked,
    signatoryName,
    signatoryId,
  ]);

  const onSaveLiabilityWaver = async () => {
    const shipmentIDs =
      shipments?.map((s) => s.id).filter((id): id is string => !!id) ?? [];

    const consigneeId = shipments[0].shipper.identificationNumber;
    const shipmentHeaderID = shipments[0].id;

    const fingerprintBMP = fingerprint
      ? base64ToFile(
          fingerprint,
          "image/bmp",
          `fingerprint-${consigneeId}-${shipmentHeaderID}.bmp`
        )
      : undefined;
    const photoPNG = photo
      ? base64ToFile(
          photo,
          "image/png",
          `photo-${consigneeId}-${shipmentHeaderID}.png`
        )
      : undefined;

    const liabilityWaiver = await saveLiabilityWaver(
      fingerprintBMP,
      photoPNG,
      shipmentIDs,
      checkboxChecked,
      signatoryName,
      signatoryId,
      user.user?.userLogin ?? "",
      1
    );

    return liabilityWaiver;
  };

  const handleDownloadDocuments = async (shipments: ShipmentInterface[]) => {
    const shipmentDocumentsRequest: ShipmentDeclaredDocumentRequestDto[] =
      shipments.map((shipment) => ({
        shipmentNumber: shipment.shipmentNumber ?? "",
        shipmentHeaderID: shipment.id,
      }));

    loaderService.start();
    alertService.info("Imprimiendo documentos...");
    const fileResult = await getAllDeclaredShipmentDocuments(
      shipmentDocumentsRequest,
      documentOrder?.documentID
    );
    loaderService.stop();
    if (!!fileResult) {
      // Create a URL for the Blob
      const fileURL = URL.createObjectURL(fileResult.file);

      // Create a link element
      const a = document.createElement("a");
      a.href = fileURL;
      a.download = fileResult.fileName; // Set the filename for the downloaded file
      a.click();

      /// In case need to force print
      /*const printWindow = window.open(fileURL, "_blank");
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.print();
        };
      } else {
        console.error("Could not open print window");
      }*/

      // Remember to handle Blob URL cleanup when done
      URL.revokeObjectURL(fileURL);
      alertService.success("Documentos impresos satisfactoriamente.");
    } else {
      alertService.error(
        "No se pudieron descargar los documentos de la orden."
      );
    }
  };

  const handlePrintLabelList = async (shipments: ShipmentInterface[]) => {
    loaderService.start();
    forEach(shipments, async (shipment) => {
      if (!shipment.shipmentNumber || !shipment.service) {
        alertService.error(
          "Error al imprimir la etiqueta",
          "No se encontraron los identificadores de la guía."
        );
        return;
      }
      alertService.info(
        "Guía: " + shipment.shipmentNumber,
        "Imprimiendo etiqueta..."
      );
      const printSucceed = await PrintShipmentLabel(
        +shipment.shipmentNumber,
        shipment.service ?? 0,
        shipment.buSource.code
      );

      if (!!printSucceed) {
        alertService.success(
          "Guía: " + shipment.shipmentNumber,
          "La etiqueta se imprimió correctamente."
        );
      }
    });
    loaderService.stop();
  };

  useEffect(() => {
    if (fingerprint) {
      setCheckboxChecked(false);
    }
  }, [fingerprint]);

  const hasFingerprint = fingerprint !== undefined;
  const hasPhoto = photo !== undefined;
  let statusMessage = "";
  let dataMessage = "";

  if (signatoryName === "" || signatoryId === "") {
    if (signatoryName === "")
      dataMessage += "El nombre del remitente es requerido. ";
    if (signatoryId === "")
      dataMessage += "La cédula de identidad es requerida. ";

    dataMessage += "(No se podrá continuar con la operación sin estos datos).";
  }
  if (hasFingerprint && hasPhoto) {
    statusMessage += `${
      shipments.length > 1 ? "Las guías tienen " : "La guía tiene "
    } cargada la huella dactilar y foto para la Declaración Jurada.`;
  } else if (!hasFingerprint && !hasPhoto) {
    statusMessage += `${
      shipments.length > 1 ? "Las guías NO tienen " : "La guía NO tiene "
    } cargada la huella dactilar ni tiene la foto para la Declaración Jurada.`;
  } else if (!hasFingerprint) {
    statusMessage += `${
      shipments.length > 1 ? "Las guías NO tienen " : "La guía NO tiene "
    }  cargada la huella dactilar pero tiene cargada la foto para la Declaración Jurada.`;
  } else if (!hasPhoto) {
    statusMessage += `${
      shipments.length > 1 ? "Las guías tienen " : "La guía tiene "
    } cargada la huella dactilar pero NO tiene cargada la foto para la Declaración Jurada.`;
  }

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex flex-row justify-between items-center mb-4">
        <h2 className="text-lg font-bold text-gray-700">Resumen de la Orden</h2>
      </div>

      <div className="flex flex-1 flex-col bg-white rounded-lg border px-8 pb-6 pt-4 gap-8">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="text-left px-4 py-2 font-semibold text-xs">
                GUÍA
              </th>

              <th className="text-left px-4 py-2 font-semibold text-xs">
                DESTINATARIO
              </th>

              <th className="text-left px-4 py-2 font-semibold text-xs">
                DESTINO
              </th>

              <th className="text-right px-4 py-2 font-semibold text-xs">
                MONTO
              </th>

              <th className="text-left px-4 py-2 font-bold text-gray-700 text-xs w-5" />
            </tr>
          </thead>
          <tbody>
            {shipments.map((shipment, i) => (
              <ShipmentItem key={i} index={i} shipment={shipment} />
            ))}
          </tbody>
        </table>

        <div>
          {dataMessage !== "" && (
            <>
              <p className="text-red-500">{dataMessage}</p>
              <br />
            </>
          )}
          <p className={!hasFingerprint || !hasPhoto ? "text-red-500" : ""}>
            {statusMessage}
          </p>
          {!hasFingerprint && (
            <div className="flex items-center mt-2">
              <input
                type="checkbox"
                checked={checkboxChecked}
                onChange={(e) => setCheckboxChecked(e.target.checked)}
              />
              <label className="ml-2">
                La toma de la huella se realizó en el documento físico
              </label>
            </div>
          )}
        </div>

        <div className="flex flex-1 gap-4 justify-start items-center">
          <SecondaryButton
            className="w-48"
            onClick={() => handlePrintLabelList(shipments)}
          >
            Imprimir Etiquetas
          </SecondaryButton>

          <SecondaryButton
            className="w-48"
            onClick={() => handleDownloadDocuments(shipments)}
          >
            Imprimir Documentos
          </SecondaryButton>

          <SecondaryButton className="w-48" onClick={() => setOpenModal(true)}>
            Cargar Declaración Jurada
          </SecondaryButton>
        </div>
      </div>

      <div className="flex flex-1 gap-4 justify-end items-center mt-8">
        <PrimaryButton
          className="w-32"
          onClick={handleFinish}
          disabled={
            (!checkboxChecked && fingerprint === undefined) ||
            signatoryName === "" ||
            signatoryId === ""
          }
        >
          <ClipboardDocumentCheckIcon
            aria-hidden="true"
            className="h-5 w-5 flex-none text-white-400 mr-2"
          />
          LISTO
        </PrimaryButton>
      </div>
      <OriginModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        shipmentNumbers={shipments
          .map((shipment) => shipment.shipmentNumber)
          .filter((number): number is string => number !== undefined)}
        shipments={shipments}
        consigneeName={
          ["J-", "G-"].includes(shipments[0].shipper.abreviationName)
            ? ""
            : shipments[0].shipper.accountFullName
        }
        consigneeId={
          ["J-", "G-"].includes(shipments[0].shipper.abreviationName)
            ? ""
            : shipments[0].shipper.identificationNumber
        }
        fingerprint={fingerprint}
        setFingerprint={setFingerprint}
        photo={photo}
        setPhoto={setPhoto}
        signatoryName={signatoryName}
        setSignatoryName={setSignatoryName}
        signatoryId={signatoryId}
        setSignatoryId={setSignatoryId}
      />
    </div>
  );
};
export default ShipmentResume;
