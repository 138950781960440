import { FC, useEffect, useState } from "react";
import Modal from "../Modal";
import { useAppSelector } from "../../store/hooks";
import { savePOD, savePODAttachment } from "../../services/podServices";
import { LinkText, PrimaryButton, SecondaryButton } from "../Buttons";
import { ShipmentInterface, ShipmentStatus } from "../../interfaces";
import FingerprintReader from "../FingerprintReader";
import WebcamCapture from "../Webcam";
import { base64ToFile } from "../../utils";
import {
  FormTextInput,
  FormFileUpload,
  FormTextAreaInput,
} from "../FormFields";
import {
  getShipment,
  alertService,
  loaderService,
  updateShipmentStatus,
} from "../../services";

interface PickupModalProps {
  openModal: boolean;
  shipmentNumbers: string[];
  shipments?: ShipmentInterface[];
  consigneeName: string;
  consigneeId: string;
  liabilityShipments: string[];
  onSubmit?: () => void;
  onDownloadPOD?: () => void;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}
export const PickupModal: FC<PickupModalProps> = ({
  openModal,
  shipments,
  shipmentNumbers,
  consigneeName,
  consigneeId,
  liabilityShipments,
  setOpenModal,
  onSubmit = () => {},
  onDownloadPOD = () => {},
}) => {
  const loading = loaderService.useIsLoading();
  const user = useAppSelector((state) => state.user);

  const [error, setError] = useState("");
  const [file, setFile] = useState<File>();
  const [nameError, setNameError] = useState("");
  const [idError, setIdError] = useState("");
  const [name, setName] = useState(consigneeName);
  const [id, setId] = useState(consigneeId);
  const [observation, setObservation] = useState("");
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [image, setImage] = useState<string | undefined>();
  const [fingerprint, setFingerprint] = useState<string | undefined>();
  const [fingerprintTemplate, setFingerprintTemplate] = useState<
    string | undefined
  >();
  const [fingerprintUploaded, setFingerprintUploaded] = useState(false);
  const [photoUploaded, setPhotoUploaded] = useState(false);

  const handleFileChange = (file: File[]) => {
    // Verify that the file is valid (PDF or image)
    if (
      file &&
      (file[0].type === "application/pdf" || file[0].type.startsWith("image/"))
    ) {
      setError("");
      setFile(file[0]);
    } else if (!!file) {
      setError("El archivo debe ser formato PDF o imagen");
    }
  };

  const handleSubmit = async () => {
    loaderService.start();

    if (!name) {
      loaderService.stop();
      setNameError("El nombre es requerido");
      return;
    }
    if (!id) {
      loaderService.stop();
      setIdError("La cédula de identidad es requerida");
      return;
    }
    setOpenModal(false);

    let shipmentsToDeliver: ShipmentInterface[] = [];
    if (!shipments) {
      // Get all shipments
      for (const shipmentNumber of shipmentNumbers) {
        const shipment = await getShipment(shipmentNumber);
        if (!!shipment) {
          shipmentsToDeliver.push(shipment);
        }
      }
    } else {
      shipmentsToDeliver = shipments;
    }

    const fingerprintBMP = fingerprint
      ? base64ToFile(fingerprint, "image/bmp", `fingerprint-${name}-${id}.bmp`)
      : undefined;
    const photoPNG = image
      ? base64ToFile(image, "image/png", `photo-${consigneeId}-${id}.png`)
      : undefined;

    for (const shipment of shipmentsToDeliver) {
      // Create POD
      const podResponse = await savePOD(
        shipment.id!,
        shipment.consignee.id,
        name,
        observation,
        user.user!,
        user.businessUnit!
      );
      if (!podResponse || !!podResponse.didError || !podResponse.model) {
        alertService.error(
          `Hubo un error al crear el POD de la guía ${shipment.shipmentNumber}.`,
          podResponse.errorMessage
        );
        loaderService.stop();
        return;
      }

      // Save POD attachment
      const attachmentResponse = await savePODAttachment(
        fingerprintBMP,
        photoPNG,
        podResponse.model.podid,
        user.user!.userLogin,
        1
      );

      if (!!attachmentResponse.didError) {
        alertService.error(
          `Hubo un error al guardar la huella y la foto de la guía ${shipment.shipmentNumber}.`
        );
        loaderService.stop();
        return;
      }

      // Update shipment status
      const response = await updateShipmentStatus(
        ShipmentStatus.DELIVERED,
        shipment.id,
        shipment.shipmentNumber,
        user.user ?? undefined
      );

      if (!!response.didError) {
        alertService.error(
          `Hubo un error al actualizar el estado de la guía ${shipment.shipmentNumber}.`
        );
        loaderService.stop();
        return;
      }
    }

    loaderService.stop();
    onSubmit();
  };

  const canSubmit = () => {
    return (
      (fingerprintUploaded || photoUploaded || checkboxChecked) &&
      !loading &&
      name &&
      id &&
      id.length >= 7
    );
  };

  useEffect(() => {
    setName(consigneeName);
    setId(consigneeId);
  }, [consigneeName, consigneeId]);

  useEffect(() => {
    setPhotoUploaded(!!image);
    setFingerprintUploaded(!!fingerprint);
  }, [image, fingerprint]);

  useEffect(() => {
    if (name === "")
      setNameError("El nombre de la persona que recibe es requerida");
    else if (name !== "") setNameError("");
  }, [name]);

  useEffect(() => {
    if (id === "") setIdError("La cédula de identidad es requerida");
    else if (id !== "") setIdError("");
    if (id.length < 7 && id !== "")
      setIdError("La cédula debe tener al menos 7 dígitos");
  }, [id]);

  return (
    <Modal
      openModal={openModal}
      setOpenModal={setOpenModal}
      className="w-full max-w-[40rem]"
    >
      <div className="flex flex-col md:flex-row md:gap-2 mb-4">
        <p className="font-light text-xl">
          Entrega de guías:{" "}
          <span className="text-indigo-600">{shipmentNumbers.join(", ")}</span>
        </p>
      </div>

      <div className="flex justify-center items-center mb-4">
        <div className="bg-gray-100 p-4 rounded border border-gray-300 text-center">
          <p className="text-sm">
            Declaro que he revisado el contenido íntegro de la encomiendo y
            libero a TEALCA de cualquier reclamo respecto de la misma.
          </p>
        </div>
      </div>

      <div className="mb-6">
        <FormTextInput
          label="Persona que recibe"
          isRequiredLabel
          name="fullName"
          type="text"
          value={name}
          error={nameError}
          onChange={(e) => {
            const value = e.target.value;
            if (/^[a-zA-Z\s]*$/.test(value)) {
              setName(e.target.value);
            }
          }}
        />
      </div>
      <div className="mb-6">
        <FormTextInput
          label="Cédula de Identidad"
          isRequiredLabel
          name="identityCard"
          type="text"
          value={id}
          error={idError}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*$/.test(value)) {
              setId(value);
            }
          }}
        />
      </div>

      <div className="flex flex-col items-center mb-6">
        <WebcamCapture photo={image} setPhoto={setImage} />
      </div>
      <div className="flex flex-col mb-6">
        <FingerprintReader
          fingerprint={fingerprint}
          setFingerprint={setFingerprint}
          setTemplate={setFingerprintTemplate}
        />
      </div>
      <div className="mb-4 hidden">
        <FormFileUpload
          error={error}
          description="El archivo debe ser formato PDF o imagen"
          onSelectFile={handleFileChange}
          className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
        />
      </div>

      {!fingerprintUploaded && (
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            id="confirmationCheckbox"
            checked={checkboxChecked}
            onChange={(e) => setCheckboxChecked(e.target.checked)}
            className="mr-2"
          />
          <label
            htmlFor="confirmationCheckbox"
            className="text-sm font-medium text-gray-900"
          >
            La huella y firma se registrarán en el documento físico
          </label>
        </div>
      )}

      {checkboxChecked && liabilityShipments.length > 0 && (
        <>
          <LinkText
            text="Imprimir documento para POD"
            onClick={() => onDownloadPOD()}
          />
          <br />
          <br />
        </>
      )}

      <FormTextAreaInput
        rows={4}
        maxLength={150}
        id="observations"
        name="observations"
        label="Observaciones"
        className="resize-none"
        onChange={(e) => setObservation(e.target.value)}
        value={observation}
      />

      <hr className="my-4" />

      <div className="flex w-full items-center justify-between">
        <SecondaryButton className="w-32" onClick={() => setOpenModal(false)}>
          Cancelar
        </SecondaryButton>

        <PrimaryButton
          disabled={!canSubmit()}
          className="w-32"
          onClick={handleSubmit}
        >
          Entregar
        </PrimaryButton>
      </div>
    </Modal>
  );
};
