import React, { Dispatch, SetStateAction } from "react";
import Modal from "../Modal";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import QuestionMarkCircleIcon from "@heroicons/react/24/outline/QuestionMarkCircleIcon";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";

type IconType = "question" | "info" | "warning" | "error" | "success";

interface ReusableModalProps {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  title: string;
  message: string;
  iconType: IconType;
  onCancel?: () => void;
  onAccept?: () => void;
  showCancelButton?: boolean;
  cancelButtonText?: string;
  acceptButtonText?: string;
}

const getIcon = (iconType: IconType) => {
  switch (iconType) {
    case "info":
      return (
        <InformationCircleIcon className="text-blue-500 h-28 w-28 mx-auto opacity-90" />
      );
    case "warning":
      return (
        <ExclamationTriangleIcon className="text-orange-500 h-28 w-28 mx-auto opacity-90" />
      );
    case "error":
      return (
        <XCircleIcon className="text-red-500 h-28 w-28 mx-auto opacity-90" />
      );
    case "question":
      return (
        <QuestionMarkCircleIcon className="text-blue-500 h-28 w-28 mx-auto opacity-90" />
      );
    case "success":
      return (
        <CheckCircleIcon className="text-green-500 h-28 w-28 mx-auto opacity-90" />
      );
    default:
      return (
        <QuestionMarkCircleIcon className="text-blue-500 h-28 w-28 mx-auto" />
      );
  }
};

const ReusableModal: React.FC<ReusableModalProps> = ({
  openModal,
  setOpenModal,
  title,
  message,
  iconType,
  onCancel,
  onAccept,
  showCancelButton = true,
  cancelButtonText = "Cancelar",
  acceptButtonText = "Aceptar",
}) => {
  return (
    <Modal openModal={openModal} setOpenModal={setOpenModal}>
      <div className="flex flex-col gap-4 max-w-md mx-auto items-center">
        <div className="flex flex-col items-center">
          {getIcon(iconType)}
          <h2 className="text-xl font-semibold leading-7 text-gray-700 text-center mt-2">
            {title}
          </h2>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-gray-600 text-center whitespace-pre-line">
            {message}
          </p>
        </div>
        <div className="flex flex-row gap-3 items-center justify-end mt-4">
          {showCancelButton && (
            <PrimaryButton
              onClick={() => {
                setOpenModal(false);
                if (onCancel) onCancel();
              }}
              className="px-4 py-2"
            >
              {cancelButtonText}
            </PrimaryButton>
          )}
          <SecondaryButton
            onClick={() => {
              setOpenModal(false);
              if (onAccept) onAccept();
            }}
            className="px-4 py-2"
          >
            {acceptButtonText}
          </SecondaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default ReusableModal;
